import React from "react";
import {LinearProgress} from "@mui/material";
import "./LinearLoading.scss";

interface LinearLoadingProps {
  isLoading: boolean;
}

export const LinearLoading = (props: LinearLoadingProps) => {
  return (
    <div className="linear-loading-container">
      {props.isLoading ? <LinearProgress /> : <div className="linear-loading-default" />}
    </div>
  );
};
