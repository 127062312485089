import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import Papa from "papaparse";
import {emailPattern} from "../../../../utils/patterns";
import {
    GoodBehaviourAllowListItem,
    ICSVFileItem
} from "../../../../views/GoodBehaviourAllowList/GoodBehaviourAllowList.types";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import "./LoadFile.scss";

export interface ILoadFileProps {
    openGuestModal: boolean;
    setNewGuestBulk: Dispatch<SetStateAction<Array<ICSVFileItem>>>;
    setOpenGuestModal: Dispatch<SetStateAction<boolean>>;
    currentList: Array<GoodBehaviourAllowListItem>;
}
export const LoadFile: React.FC<ILoadFileProps> = ({setNewGuestBulk, setOpenGuestModal, currentList, openGuestModal}) => {
    const [file, setFile] = useState("No file selected");

    useEffect(() => {
        if (!openGuestModal) {
            setFile("No file selected");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openGuestModal]);

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        const file = event.target.files[0];
        setFile(file?.name);
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            transformHeader: ((header: string) => header.toLowerCase()),
            complete: function (results: { data: Array<ICSVFileItem> }) {
                const list: Array<ICSVFileItem> = [];

                for (const item of results.data) {
                    list.push({
                        email: item.email?.toLowerCase(),
                        name: item.name,
                        valid: emailPattern.test(item.email) && isNotDuplicated(item.email, list)
                    });
                }

                setNewGuestBulk(list);
                setOpenGuestModal(true);
            },
        });
    };

    const isNotDuplicated = (value: string, list: Array<ICSVFileItem>): boolean => {
        const notInCurrentList= !currentList.find((item: GoodBehaviourAllowListItem) => item.email?.toLowerCase() === value.toLowerCase());
        const notInNewList= !list.find((item: ICSVFileItem) => item.email?.toLowerCase() === value.toLowerCase());
        return notInCurrentList && notInNewList;
    }

    return (
        <div>
            <input
                id="guest_csv_file"
                type="file"
                name="file"
                onChange={changeHandler}
                accept=".csv"
                value=""
                hidden
            />
            <label htmlFor="guest_csv_file" className="custom-file-upload">
                <FileUploadIcon fontSize="small" style={{marginBottom: -5}}/> Upload Bulk
            </label>

            <span className="filename">{file}</span>
        </div>
    );
}
