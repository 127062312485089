import React, {useState} from "react";
import {Row, useAsyncDebounce} from "react-table";
import {InputAdornment, TextField} from "@mui/material";
import {Search} from "@mui/icons-material";

interface GlobalFilterProps {
  preGlobalFilteredRows: Row<any>[];
  globalFilter: any;
  setGlobalFilter: (value: any) => void;
  delay?: number;
  placeholder?: string;
  handleKeyDown?: () => void;
  isDisabled?: boolean;
}

export const GlobalFilter = (props: GlobalFilterProps) => {
  const [value, setValue] = useState(props.globalFilter);

  const onChange = useAsyncDebounce((value) => {
    props.setGlobalFilter(value || undefined);
  }, props.delay ?? 200);

  const onKeyDown = () => {
      if (props.handleKeyDown) {
          props.handleKeyDown();
      }
  }

  return (
    <div>
      <TextField
        disabled={props.isDisabled}
        placeholder={props.placeholder ?? "Search"}
        id="outlined-size-small"
        style={{minWidth: 350, width: "100%"}}
        size="small"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        onKeyDown={(e) => {
            if (e.key === 'Enter') {
                onKeyDown();
            }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
