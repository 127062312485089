import axios from "./axios";
import {VerifiedUserInterface} from "../views/PassedValidationAllowList/PassedValidationAllowList.types";
import {
  GoodBehaviourAllowListInterface,
  LockType,
  UnitAllowListInterface,
  VerificationType
} from "../modules/verification/types";
import { PaginationInterface } from "../modules/common/types/Pagination.types";

export const getAllVerifications = () => {
  return axios.get(`/private/verifications/all`);
};

export const getListLocks = (verificationIds: Array<string>) => {
  return axios.post<Array<string>>(`/private/locks/lists`, {verificationIds});
};

export const getChannels = () => {
  return axios.get(`/private/locks/lists`);
};

export const getVerification = (verificationId: number) => {
  return axios.get(`/private/verifications/${verificationId}`);
};

export const getReservation = (reservationId: number) => {
  return axios.get(`/private/reservations/${reservationId}`);
};

export const getChannelByAdminId = (channelId: number) => {
  return axios.get(`/private/channels/admin/${channelId}`);
};

export const manualSuccessVerification = (verificationId: number, reason_id: number, reason_detail: string) => {
  return axios.put(`/private/verifications/override/success/${verificationId}`, {reason_id, reason_detail});
};

export const manualFailVerification = (verificationId: number, reason_id: number, reason_detail: string) => {
  return axios.put(`/private/verifications/override/fail/${verificationId}`, {reason_id, reason_detail});
};

export const sendEmail = (verificationId: number) => {
  return axios.put(`/private/verifications/email/${verificationId}`);
};

export const sendSMS = (verificationId: number) => {
  return axios.put(`/private/verifications/sms/${verificationId}`);
};

export const addCXNotification = (verificationId: number) => {
  return axios.put(`/private/verifications/cx/${verificationId}`);
};

export const sendCustomNotification = (type: string, verificationId: number, to: string) => {
  return axios.post(`/private/verifications/custom-notification/${type}/${verificationId}`, {to});
};

export const addVerificationLock = (verificationId: number) => {
  return axios.post<LockType>(`/private/locks/${verificationId}`);
};

export const removeVerificationLock = (verificationId: number) => {
  return axios.post(`/private/locks/remove/${verificationId}`);
};

export const getVerificationByReservation = (reservation_id: number) => {
  return axios.get<Array<VerificationType>>(`/private/verifications/reservation/${reservation_id}`);
};

export const getReasons = () => {
  return axios.get(`/private/reasons`);
};

export const getListByName = (name: string) => {
  return axios.get<UnitAllowListInterface>(`/private/lists?name=${name}`);
};

export const getGoodBehaviourList = () => {
  return axios.get<GoodBehaviourAllowListInterface>(`/private/good-behaviour-guests`);
};

export const saveGoodBehaviourGuests = (guests: Array<Partial<GoodBehaviourAllowListInterface>>) => {
  return axios.post<GoodBehaviourAllowListInterface>(`/private/good-behaviour-guests`, {data: guests});
};

export const deleteGoodBehaviourGuest = (id: number) => {
  return axios.delete<GoodBehaviourAllowListInterface>(`/private/good-behaviour-guests/${id}`);
};

export const updateList = (id: number, list: any) => {
  return axios.put<UnitAllowListInterface>(`/private/lists/${id}`, list);
};

export const getVerifiedUsers = (queryParameters: string) => {
  return axios.get<PaginationInterface<VerifiedUserInterface>>(`/private/verified-users${queryParameters}`);
};

export const deleteVerifiedUsers = (verifiedUserId: number) => {
  return axios.delete<{}>(`/private/verified-users/${verifiedUserId}`);
};
