import React from "react";
import {
  Box,
  TableFooter as MaterialTableFooter,
  TablePagination as MaterialTablePagination,
  TableRow,
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";

interface TableFooterProps {
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageCount: number;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (pageSize: number) => void;
  pageIndex: number;
  pageSize: number;
  rows: number;
  colSpan: number;
}

export const TableFooter = (props: TableFooterProps) => {
  const theme = useTheme();
  const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    props.gotoPage(page);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.setPageSize(parseInt(event.target.value, 10));
    props.gotoPage(0);
  };
  return (
    <MaterialTableFooter>
      <TableRow>
        <MaterialTablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          colSpan={props.colSpan}
          count={props.rows}
          rowsPerPage={props.pageSize}
          page={props.pageIndex}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          onPageChange={onPageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={() => {
            return (
              <Box sx={{flexShrink: 0, ml: 2.5}}>
                <IconButton onClick={() => props.gotoPage(0)} disabled={!props.canPreviousPage} aria-label="first page">
                  {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                  onClick={() => props.previousPage()}
                  disabled={!props.canPreviousPage}
                  aria-label="previous page"
                >
                  {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton onClick={() => props.nextPage()} disabled={!props.canNextPage} aria-label="next page">
                  {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                  onClick={() => props.gotoPage(props.pageCount - 1)}
                  disabled={!props.canNextPage}
                  aria-label="last page"
                >
                  {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
              </Box>
            );
          }}
        />
      </TableRow>
    </MaterialTableFooter>
  );
};
