import React, {useState} from "react";
import {TableOptions, useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import TableFooter from "@mui/material/TableFooter";
import {VerificationTablePagination} from "./VerificationTablePagination";
import {VerificationTableToolbar} from "./VerificationTableToolbar";
import useVerificationsData from "./hooks/useVerificationsData";
import {IModalItem, VerificationDetail} from "../VerificationDetail/VerificationDetail";
import moment from "moment";
import {TextFieldFilter} from "../../../common/components/Filters/TextFieldFilter/TextFieldFilter";
import {DateRangeFilter} from "../../../common/components/Filters/DateRangeFilter/DateRangeFilter";
import {MultiSelectFilter} from "../../../common/components/Filters/MultiSelectFilter/MultiSelectFilter";
import {VerificationStatus} from "../VerificationStatus/VerificationStatus";
import IconButton from "@mui/material/IconButton";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {VerificationItem} from "../../types";
import {getFullDate} from "../../../../utils/formats";
import LockIcon from "@mui/icons-material/Lock";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export const VerificationTable = () => {
  const {data, isLoading, liveMode, toggleMode, refresh} = useVerificationsData();
  const [open, setOpen] = useState(false);
  const [modalItem, setModalItem] = useState<IModalItem | undefined>(undefined);

  const filterTypes = React.useMemo(
    () => ({
      // Date Range Filter
      date: (rows: any[], id: any, filterValue: any) => {
        let start = moment(filterValue[0]).subtract(1, "day");
        let end = moment(filterValue[1]).add(1, "day");
        return rows.filter((val) => val.original[id] && moment(val.original[id]).isBetween(start, end));
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: TextFieldFilter,
    }),
    []
  );

  const onShow = async (element: VerificationItem) => {
    setModalItem({
      verificationId: element.id,
      reservationId: element.reservation_id,
      channelId: element.channel_id,
    });
    setOpen(true);
  };

  const columns = React.useMemo(
    () => [
      {
        accessor: "created_at",
        Header: "Created at",
        Filter: DateRangeFilter,
        filter: "date",
        width: "100%",

        Cell: (props: any) => {
          const dateStr = getFullDate(props.row.original?.created_at);
          return <div>{dateStr}</div>;
        },
      },
      {
        Header: "Reservation ID",
        accessor: "reservation_id",
        width: "100%",
      },
      {
        Header: "Channel",
        accessor: "channel",
        width: "100%",
      },
      {
        Header: "Check in (PT Timezone)",
        accessor: "check_in",
        Filter: DateRangeFilter,
        filter: "date",
        width: "100%",
        Cell: (props: any) => {
          const check_in = props?.row?.original?.check_in;

          const dateStr = check_in ? getFullDate(check_in) : "-";
          return <div>{dateStr}</div>;
        },
      },
      {
        Header: "Check in (Unit Timezone)",
        accessor: "check_in_tz",
        Filter: DateRangeFilter,
        filter: "date",
        width: "100%",
        Cell: (props: any) => {
          const check_in_tz = props?.row?.original?.check_in_tz;

          const dateStr = check_in_tz ?? "-";
          return <div>{dateStr}</div>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        width: 200,
        Filter: MultiSelectFilter,
        filter: "includesSome",
        Cell: (props: any) => {
          const {status, status_color} = props.row.original;
          return <VerificationStatus label={status} color={status_color} />;
        },
      },
      {
        Header: "Actions",
        width: 40,

        Cell: (props: any) => {
          const data = props.row.original as VerificationItem;
          return (
            <Box style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              {data.isLock ? <LockIcon /> : <Box style={{height: 24, width: 24}} />}
              <IconButton onClick={() => onShow(data)} aria-label="first page">
                <VisibilityOutlinedIcon />
              </IconButton>
              <Box style={{height: 24, width: 24}} />
            </Box>
          );
        },
      },
    ],
    // eslint-disable-next-line
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setAllFilters,
    state: {pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      autoResetPage: false,
    } as TableOptions<VerificationItem>,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = Math.max(0, (1 + pageIndex) * pageSize - rows.length);

  return (
    <Box style={{border: "solid 1px #99adb6", borderRadius: 5, backgroundColor: "#ffffff"}}>
      <VerificationDetail open={open} onClose={() => setOpen(false)} onListRefresh={refresh} modalItem={modalItem} />
      <VerificationTableToolbar
        isLoading={isLoading}
        rows={rows as any}
        clearFilters={() => setAllFilters([])}
        liveMode={liveMode}
        toggleMode={toggleMode}
      />
      <TableContainer>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps({
                      style: {
                        width: column.width === "100%" ? undefined : column.width,
                        verticalAlign: "top",
                      },
                    })}
                  >
                    <Box style={{width: column.width}} {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <Box display="flex" alignItems="center">
                        {column.render("Header")}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ExpandMoreIcon />
                          ) : (
                            <ExpandLessIcon />
                          )
                        ) : (
                          <Box height={24} width={24}>
                            {column.id === "Actions" ? null : <ExpandMoreIcon width={24} height={24} opacity={0.5} />}
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Box style={{width: column.width}}>{column.canFilter ? column.render("Filter") : null}</Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()} style={{textAlign: "center", padding: "4px"}}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{height: 49 * emptyRows}}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <VerificationTablePagination
                {...{
                  canPreviousPage,
                  canNextPage,
                  pageCount,
                  gotoPage,
                  nextPage,
                  previousPage,
                  setPageSize,
                  pageIndex,
                  pageSize,
                }}
                rows={rows.length}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};
