import moment from "moment";
import {FrontendConfiguration} from "../config/frontend.config";

const getMomentWithPortlandTimeZone = (date: string) => {
  return moment(date).tz(FrontendConfiguration.getPortlandTimeZone());
};

const getMomentWithSelectedTimeZone = (date: string, timezone: string) => {
  return moment(date).tz(timezone);
};

export const getShortDate = (date: string): string => {
  return getMomentWithPortlandTimeZone(date).format(FrontendConfiguration.getShortDateFormat());
};

export const getFullDate = (date: string): string => {
  return getMomentWithPortlandTimeZone(date).format(FrontendConfiguration.getFullDateFormat());
};

export const getFullDateWithTZ = (date: string, timezone: string): string => {
  if(!timezone) {
    return getMomentWithPortlandTimeZone(date).format(FrontendConfiguration.getFullDateFormat());
  }
  return getMomentWithSelectedTimeZone(date, timezone).format(FrontendConfiguration.getFullDateFormat());
};
