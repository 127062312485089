import React, {ReactNode} from "react";
import {Grid} from "@mui/material";
import "./TableToolbar.scss";

interface TableToolbarProps {
  title?: ReactNode;
  children?: ReactNode;
}

export const TableToolbar = (props: TableToolbarProps) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className="table-toolbar-container"
    >
      <Grid item>
        <div className="table-toolbar-title"><b>{props.title}</b></div>
      </Grid>
      <Grid item>
        <div className="table-toolbar-children">{props.children}</div>
      </Grid>
    </Grid>
  );
};
