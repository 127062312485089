import { useState } from "react";
import { HeaderGroup } from "react-table";
import { PaginationMetaInterface } from "../modules/common/types/Pagination.types";

const paginationMetaInitial: PaginationMetaInterface = {
    page: 1,
    count: 1,
    limit: 10,
    itemCount: 1,
    orderBy: 'id',
    hasNextPage: false,
    hasPreviousPage: false,
    search: '',
}

export const useCustomPagination = () => {
    const [paginationMeta, setPaginationMeta] = useState<PaginationMetaInterface>(paginationMetaInitial);
    const [queryParameters, setQueryParameters] = useState('');
    const [searchFilter, setSearchFilter] = useState('');

    const setNextPage = () => {
        paginationMeta.page += 1;
        setPaginationMeta(paginationMeta)
        createQueryParameters();
    };

    const setPreviousPage = () => {
        paginationMeta.page -= 1;
        setPaginationMeta(paginationMeta)
        createQueryParameters();
    }

    const setFirstOrLastPage = (pageIndex: number) => {
        paginationMeta.page = pageIndex ? paginationMeta.itemCount : 1;
        setPaginationMeta(paginationMeta)
        createQueryParameters();
    }

    const setPageLimit = (pageSize: any): void => {
        paginationMeta.page = 1;
        paginationMeta.limit = pageSize;
        setPaginationMeta(paginationMeta)
        createQueryParameters();
    }

    const setOrderBy = (headerGroups: HeaderGroup<any>[]): void => {
        const header = headerGroups[0].headers.find((header) => header.isSorted)
        if (!header) {
            return;
        }

        const orderBy = createOrderFormat(header);

        if (paginationMeta.orderBy === orderBy) {
            return
        }

        paginationMeta.page = 1;
        paginationMeta.orderBy = orderBy;
        setPaginationMeta(paginationMeta)
        createQueryParameters();
    }

    const createOrderFormat = (header: HeaderGroup<any>): string => {
        return header.isSortedDesc ? `-${header.id}` : header.id;
    }

    const createQueryParameters = () => {
        const query = `?limit=${paginationMeta.limit}&page=${paginationMeta.page}&orderBy=${paginationMeta.orderBy}&search=${paginationMeta.search}`;
        setQueryParameters(query);
    }

    const handleOnChangeFilter = (value: string) => {
        setSearchFilter(value);
    }

    const setSearch = () => {
        paginationMeta.page = 1;
        paginationMeta.search = searchFilter === undefined ? '' : searchFilter;

        setPaginationMeta(paginationMeta)
        createQueryParameters();
    }

    return {
        setSearch,
        setOrderBy,
        setNextPage,
        setPageLimit,
        paginationMeta,
        queryParameters,
        setPreviousPage,
        setPaginationMeta,
        setFirstOrLastPage,
        handleOnChangeFilter,
    }
}