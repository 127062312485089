import {StepNames} from "../../constants";
import * as _ from "lodash";
import {IDologyResponse} from "../../types";
import moment from "moment";
import {FrontendConfiguration} from "../../../../config/frontend.config";
import {ReactNode} from "react";
import {Box} from "@mui/material";

export class UtilComponent {
    public static stepTranslate(step: string): string {
        switch (step) {
            case StepNames.REQUESTED:
                return "Verification requested";
            case StepNames.NOTIFIED:
                return "Email sent";
            case StepNames.NOTIFIED_SMS:
                return "SMS sent";
            case StepNames.CX:
                return "Guest was contacted";
            case StepNames.CLIENT:
                return "Result was notified to Admin";
            case StepNames.WELCOME:
                return "Welcome";
            case StepNames.EXTRA_INFORMATION:
                return "Extra Information needed";
            case StepNames.IDIQ_REQUEST:
                return "Initial request to ID Service";
            case StepNames.QUESTIONS:
                return "Questions";
            case StepNames.DIFFERENTIATOR:
                return "Differentiator question";
            case StepNames.CHALLENGE_REQUEST:
                return "Challenge requested to ID Service";
            case StepNames.CHALLENGE:
                return "Challenge question";
            case StepNames.SUCCESS:
                return "Success";
            case StepNames.MANUAL_SUCCESS:
                return "Manual Success";
            case StepNames.SOFT_FAIL:
                return "Soft Fail";
            case StepNames.HARD_FAIL:
                return "Hard Fail";
            case StepNames.MANUAL_CANCEL:
                return "Manual Cancel";
            case StepNames.ID_SERVICE_ERROR:
                return "ID Service returned an error";
            case StepNames.ERROR:
                return "Application error";
            case StepNames.IGNORED_COUNTRY_NOT_ACTIVE:
                return "Ignored, country is not active";
            case StepNames.IGNORED_CHANNEL_NOT_ACTIVE:
                return "Ignored, channel is not active";
            case StepNames.IGNORED_THRESHOLD_NOT_DEFINED:
                return "Ignored, threshold is not defined";
            case StepNames.IGNORED_THRESHOLD_NOT_REACHED:
                return "Ignored, threshold was not reached";
            case StepNames.IGNORED_RESERVATION_NOT_FOUND:
                return "Ignored, reservation was not found";
            case StepNames.IGNORED_RESERVATION_NOT_OK:
                return "Ignored, reservation is not valid";
            case StepNames.IGNORED_RESERVATION_CANCELLED:
                return "Ignored, reservation is cancelled";
            default:
                return step;
        }
    }

    public static createHistoryItem(
        historyName: string,
        historyDetail: string | ReactNode,
        created_at: Date,
        updated_at: Date,
        verificationCreatedAt: Date
    ) {
        const createdAt = moment(created_at).toDate();
        const updatedAt = moment(updated_at).toDate();

        const extraDetail = (
            <Box component="span">{historyDetail ? <Box component="span"> / {historyDetail}</Box> : null}</Box>
        );

        return {
            created_at: createdAt,
            gen_created_at: moment(createdAt).format(""),
            finished_at: updatedAt,
            age: moment.duration(moment(verificationCreatedAt).diff(moment(updatedAt))).humanize(),

            duration: moment.duration(moment(updatedAt).diff(moment(createdAt))).humanize(),

            short_name: historyName,
            detail: (
                <Box component="span">
                    {UtilComponent.stepTranslate(historyName)}
                    {extraDetail}
                </Box>
            ),
        };
    }

    public static getIDologyResult(name: string, result: IDologyResponse.IDIQ): ReactNode {
        if (_.isNil(result)) {
            return "";
        }

        const errors = _.concat(result.error || [], result.failed || []);

        if (!_.isEmpty(errors)) {
            return errors.join(", ");
        }

        switch (name) {
            case StepNames.IDIQ_REQUEST:
                return UtilComponent.processIDology_IDIQ_REQUEST(result);
            case StepNames.DIFFERENTIATOR:
                return UtilComponent.processIDology_IDIQ_REQUEST(result);
            case StepNames.QUESTIONS:
                return UtilComponent.processIDology_QUESTIONS(result);
            case StepNames.CHALLENGE_REQUEST:
                return UtilComponent.processIDology_CHALLENGE_REQUEST(result);
            case StepNames.CHALLENGE:
                return UtilComponent.processIDology_CHALLENGE(result);
            case StepNames.HARD_FAIL:
                return UtilComponent.processIDology_HARD_FAIL(result);
            default:
                return "";
        }
    }

    public static getNotificationLink(notificationId: number, type: number): string {
        return `${FrontendConfiguration.getNotificationsUrl()}/activity-search?filter[advanced._id]=${notificationId}&filter[advanced.notification_type_id]=${type}`;
    }

    private static processIDology_HARD_FAIL(oresult: any): any {
        const result = _.cloneDeep(oresult);
        const extraDetails = result.map((r: any) => <div> - {r.message} </div>)
        return <div> Extra information (Do not share this information): {extraDetails} </div>
    }

    private static processIDology_IDIQ_REQUEST(oresult: IDologyResponse.IDIQ): ReactNode {
        const result = _.cloneDeep(oresult);

        let idologyId = <div>IDology ID: {result["id-number"]}</div>
        delete result["id-number"];

        let finalResult = <div>Result: {result?.results?.key}, message: {result?.results?.message}</div>
        delete result.results;

        let summaryResult = <div>Summary Result: {result["summary-result"]?.key},
            message: {result["summary-result"]?.message}</div>;
        delete result["summary-result"];

        let resultQualifiers = undefined;
        let idliveqError = undefined;
        let resultQuestions = undefined;
        let differentiatorQuestion = undefined;
        let velocityResults = undefined;

        if (result.qualifiers) {
            resultQualifiers =
                <div>Qualifiers: {
                    _.chain(result.qualifiers)
                        .map((q) => _.get(q, "message", ""))
                        .join(", ")
                        .value()}
                </div>
            delete result.qualifiers;
        }

        if (result["idliveq-error"]) {
            idliveqError = <div>Idliveq Error: {result["idliveq-error"].message}</div>
            delete result["idliveq-error"];
        }

        if (result.questions) {
            resultQuestions =
                <div>Questions: {
                    _.chain(result.questions)
                        .map((q) => q.type)
                        .join(", ")
                        .value()}
                </div>;
            delete result.questions;
        }

        if (result["differentiator-question"]) {
            differentiatorQuestion = <div>Differentiator question: {result["differentiator-question"].type}</div>;
            delete result["differentiator-question"];
        }

        if (result["velocity-results"]) {
            velocityResults =
                <div>Velocity Results: {
                    _.chain(result["velocity-results"])
                        .map((v) => v.message)
                        .join(", ")
                        .value()}
                </div>;
            delete result["velocity-results"];
        }
        return <div>
            {idologyId}
            {finalResult}
            {summaryResult}
            {resultQualifiers}
            {idliveqError}
            {resultQuestions}
            {differentiatorQuestion}
            {velocityResults}
            {this.manageUnhandledInfo(result)}

        </div>
    }

    private static processIDology_QUESTIONS(oresult: IDologyResponse.IDIQ): ReactNode {
        const result = _.cloneDeep(oresult);

        let idologyId = <div>IDology ID: {result["id-number"]}</div>;
        let answersReceived = undefined;
        let idliveqResult = undefined;
        let iqSummaryResult = undefined;

        delete result["id-number"];
        delete result.results;
        delete result["summary-result"];
        delete result.qualifiers;
        delete result["idliveq-error"];
        delete result.questions;
        delete result["differentiator-question"];
        delete result["velocity-results"];
        delete result["located-email-record"];

        if (!result["answers-received"] === undefined) {
            answersReceived = <div>Answers Received: {result["answers-received"]}</div>;
            delete result["answers-received"];
        }

        if (result["idliveq-result"]) {
            idliveqResult = <div>Idliveq Result: {result["idliveq-result"].message} </div>;
            delete result["idliveq-result"];
        }

        if (result["iq-summary-result"]) {
            iqSummaryResult = <div>Result: {result["iq-summary-result"]} </div>;
            delete result["iq-summary-result"];
        }
        return <div>
            {idologyId}
            {answersReceived}
            {idliveqResult}
            {iqSummaryResult}
            {this.manageUnhandledInfo(result)}
        </div>
    }

    private static processIDology_CHALLENGE_REQUEST(oresult: IDologyResponse.IDIQ): ReactNode {
        const result = _.cloneDeep(oresult);

        let idologyId = <div>IDology ID {result["id-number"]}</div>;
        let resultQuestions = undefined;
        delete result["id-number"];
        delete result.results;
        delete result["summary-result"];
        delete result.qualifiers;
        delete result["idliveq-error"];
        delete result["idliveq-result"];
        delete result["iq-summary-result"];

        if (result.questions) {
            resultQuestions =
                <div>Challenge:
                    {_.chain(result.questions)
                        .map((q) => q.type)
                        .join(", ")
                        .value()}
                </div>
            delete result.questions;
        }
        return <div>
            {idologyId}
            {resultQuestions}
            {this.manageUnhandledInfo(result)}
        </div>
    }

    private static processIDology_CHALLENGE(oresult: IDologyResponse.IDIQ): ReactNode {
        const result = _.cloneDeep(oresult);

        const idologyId = <div>IDology ID: {result["id-number"]}</div>;
        let answersReceived = undefined;
        let idChallengeResult = undefined;
        let summaryResult = undefined;

        delete result["id-number"];
        delete result.results;
        delete result["summary-result"];
        delete result.qualifiers;
        delete result["idliveq-result"];
        delete result["iq-summary-result"];

        if (result["answers-received"]) {
            answersReceived =
                <div>Answers Received: {result["answers-received"]} (includes previouly answered questions)</div>;
            delete result["answers-received"];
        }

        if (result["idliveq-challenge-result"]) {
            idChallengeResult = <div>Idliveq Challenge Result: {result["idliveq-challenge-result"].message}</div>
            ;
            delete result["idliveq-challenge-result"];
        }

        if (result["iq-challenge-summary-result"]) {
            summaryResult = <div>Result: {result["iq-challenge-summary-result"]}</div>;
            delete result["iq-challenge-summary-result"];
        }

        return <div>
            {idologyId}
            {answersReceived}
            {idChallengeResult}
            {summaryResult}
            <div>{this.manageUnhandledInfo(result)}</div>
        </div>
    }

    private static manageUnhandledInfo(result: any): ReactNode {
        return !_.isEmpty(result) ? <div>Unhandled info: {JSON.stringify(result)}</div> : <div></div>;
    }
}
