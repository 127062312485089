import {ReactNode, useCallback} from "react";
import {getVerification} from "../../../../../services/idv.service";
import {UtilComponent} from "../utils-component";
import {NotificationTypes, StepNames} from "../../../constants";
import {StepType} from "../../../types";
import {getShortDate} from "../../../../../utils/formats";
import * as _ from "lodash";
import {Box} from "@mui/material";
import {getEnhancedStatus} from "../../../utils";

const useRefreshVerification = (verificationId: number | undefined, setVerification: (verification: any) => void) => {
  const refreshVerification = useCallback(async () => {
    if (verificationId === undefined) {
      setVerification(undefined);
      return;
    }
    let verification: any;
    try {
      const response = await getVerification(verificationId);
      verification = response.data;
    } catch (err) {
      console.error("Error fetching verification");
      return;
    }

    const history = [];
    history.push(
      UtilComponent.createHistoryItem(
        StepNames.REQUESTED,
        "Verification process was requested",
        verification?.client_requested_at,
        verification?.client_requested_at,
        verification?.created_at
      )
    );

    history.push(
      ..._.map(verification?.steps, (step: StepType) => {
        let iDologyResult: ReactNode;
        try {
          iDologyResult = UtilComponent.getIDologyResult(step.name, step.result);
        } catch (e: any) {
          iDologyResult = <div>IDV Parsing error, please contact team Circo Pobre. {e.message}</div>;
        }
        let historyDetail = (
          <div>
            <span>{step.detail ? step.detail + ". " : ""}</span>
            <span>{iDologyResult}</span>
          </div>
        );

        let stepName = step.name;
        if (stepName.startsWith("IGNORED")) {
          //by now we are considering ignored as passed
          stepName = StepNames.SUCCESS;
          historyDetail = <div>{UtilComponent.stepTranslate(step.name)}</div>;
        }
        return UtilComponent.createHistoryItem(
          stepName,
          historyDetail,
          step.created_at,
          step.updated_at,
          verification.created_at
        );
      })
    );

    history.push(
      ..._.map(verification?.notifications, (notification) => {
        let description = null;
        let historyName: string;
        switch (notification.type) {
          case NotificationTypes.EMAIL:
            description = (
              <Box component="span">
                Notification {/* eslint-disable-next-line*/}
                <a
                  href={UtilComponent.getNotificationLink(notification.email_notification_id, 1)}
                  target="_blank"
                  style={{textDecoration: "none", color: "#337ab7"}}
                >
                  {notification.email_notification_id}
                </a>
                {notification.user ? ` sent by ${notification.user.email}` : ""}
                {notification.to ? ` to ${notification.to}.` : "."}
              </Box>
            );

            historyName = StepNames.NOTIFIED;
            break;
          case NotificationTypes.SMS:
            description = (
              <Box component="span">
                Notification{" "}
                {notification.email_notification_id ? (
                  //eslint-disable-next-line
                  <a
                    href={UtilComponent.getNotificationLink(notification.email_notification_id, 3)}
                    target="_blank"
                    style={{textDecoration: "none", color: "#337ab7"}}
                  >
                    {notification.email_notification_id}
                  </a>
                ) : null}
                {notification.user ? ` sent by ${notification.user.email}` : ""}
                {notification.to ? ` to ${notification.to}.` : "."}
              </Box>
            );
            historyName = StepNames.NOTIFIED_SMS;
            break;
          case NotificationTypes.CX:
            description = <Box component="span">{notification.user.email}</Box>;
            historyName = StepNames.CX;
            break;
          case NotificationTypes.CLIENT:
            historyName = StepNames.CLIENT;
            break;
          default:
            description = <Box component="span">Unknown notification</Box>;
            historyName = "NOTIFICATION";
        }

        return UtilComponent.createHistoryItem(
          historyName,
          description,
          notification.created_at,
          notification.updated_at,
          verification.created_at
        );
      })
    );

    const enhancedStatus = getEnhancedStatus(verification?.status, verification?.status_detail);

    const newModalData = {
      created_at: getShortDate(verification?.created_at),
      updated_at: getShortDate(verification?.updated_at),
      client_requested_at: getShortDate(verification?.client_requested_at),
      guest_name: (verification?.first_name || "").trim() + " " + (verification?.last_name || "").trim(),
      client_id: verification?.client_id,
      status: enhancedStatus.name,
      status_color: enhancedStatus.color,
      verification: verification,
      history: _.sortBy(history, (h) => h.created_at),
    };

    setVerification(newModalData);
  }, [verificationId, setVerification]);

  return refreshVerification;
};

export default useRefreshVerification;
