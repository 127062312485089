import axios from "axios";
import {FrontendConfiguration} from "../config/frontend.config";

// Passing configuration object to axios
const instance = axios.create({
  baseURL: FrontendConfiguration.apiUrl(),
});

instance.interceptors.request.use(
  (request) => {
    const access_token = localStorage.getItem("access_token") ?? "";
    request.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    };
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (FrontendConfiguration.isLocalEnv()) {
      console.log(response);
    }
    return response;
  },
  (error) => {
    if (FrontendConfiguration.isLocalEnv()) {
      console.error(error);
    }
    return Promise.reject(error);
  }
);

export default instance;
