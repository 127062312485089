import {useCallback} from "react";
import {getReasons} from "../../../../../services/idv.service";

const useRefreshReasons = (setReasons: (reasons: any) => void) => {
    const refreshReason = useCallback(async () => {
        try {
            const {data: reasons} = await getReasons();
            reasons.map((item: any) => ({...item, show: false}));
            setReasons(reasons);
        } catch (err) {
            console.error("Error fetching reasons");
        }
    }, [setReasons]);
    return refreshReason;
};
export default useRefreshReasons;
