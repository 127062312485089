import React from "react";
import {Chip, Tooltip} from "@mui/material";
import {VerificationStatusColor} from "../../types";

export interface IVerificationStatusProps {
  label: string;
  color: VerificationStatusColor;
}

export const VerificationStatus = (props: IVerificationStatusProps) => {
  const {label, color} = props;
  return (
    <Tooltip title={label}>
      <Chip label={label} color={color} style={{width: 95}} />
    </Tooltip>
  );
};
