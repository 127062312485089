import React from "react";
import {Box, TableCell, TableHead as MaterialTableHead, TableRow} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {HeaderGroup} from "react-table";

interface TableHeadProps {
    headerGroups: HeaderGroup<any>[];
}

export function TableHead(props: TableHeadProps) {
    return (
        <MaterialTableHead>
            {props.headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                        <TableCell
                            {...column.getHeaderProps({
                                style: {
                                    width: column.width === "100%" ? undefined : column.width,
                                    verticalAlign: "top",
                                },
                            })}
                        >
                            <Box style={{width: column.width}} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    {column.render("Header")}
                                    {column.isSorted ? (
                                        column.isSortedDesc ? (
                                            <ExpandMoreIcon />
                                        ) : (
                                            <ExpandLessIcon />
                                        )
                                    ) : (
                                        <Box height={24} width={24}>
                                            {column.id === "Actions" ? null : <ExpandMoreIcon width={24} height={24} opacity={0.5} />}
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </MaterialTableHead>
    );
}
