import React, {useEffect, useState} from "react";
import {Box, Grid, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import {Row, useAsyncDebounce} from "react-table";
import {CsvBuilder} from "filefy";
import PauseCircleOutlinedIcon from "@mui/icons-material/PauseCircleOutlined";

import {VerificationItem} from "../../types";
import moment from "moment-timezone";

export interface ITableToolbarProps {
  isLoading: boolean;
  rows?: Array<Row>;
  clearFilters: () => void;
  liveMode: boolean;
  toggleMode: () => void;
}

const exportCsvMapper = (rows: Array<Row>) => {
  return rows.map((el) => {
    const {channel, check_in, created_at, reservation_id, status} = el.original as VerificationItem;
    const created_at_str = moment(created_at).tz("America/Los_Angeles").format("YYYY-MM-DD HH:MM:SS");
    const check_in_str = moment(check_in).tz("America/Los_Angeles").format("YYYY-MM-DD HH:MM:SS");
    return [created_at_str, String(reservation_id), channel, check_in_str, status];
  });
};

const exportCsv = (rows: Row[]) => {
  const csvBuilder = new CsvBuilder("action-needed.csv")
    .setColumns(["Created at", "Reservation ID", "Channel", "Check in", "Status"])
    .addRows(exportCsvMapper(rows));
  csvBuilder.exportFile();
};

export const VerificationTableToolbar = (props: ITableToolbarProps) => {
  const {isLoading = false, rows, clearFilters, toggleMode, liveMode} = props;
  const [isLoadingInternal, setIsLoadingInternal] = useState(isLoading);

  const setIsLoadingInternalWithDebounce = useAsyncDebounce((value) => {
    setIsLoadingInternal(value);
  }, 1250);

  useEffect(() => {
    if (isLoading) {
      setIsLoadingInternal(isLoading);
    } else {
      setIsLoadingInternalWithDebounce(isLoading);
    }
  }, [isLoading, setIsLoadingInternalWithDebounce]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{borderBottom: "solid 1px #99adb6"}}
    >
      <Grid item>
        <Box sx={{m: 2, fontFamily: "Nunito Sans, sans-serif"}}>Action Needed</Box>
      </Grid>
      <Grid item>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Box sx={{mr: 2}}>{isLoadingInternal ? "Loading..." : ""}</Box>

          <Tooltip title="Clear all filters">
            <IconButton onClick={() => clearFilters()} aria-label="clear filter">
              <FilterListOffOutlinedIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Export as csv">
            <IconButton onClick={() => exportCsv(rows as Row[])} aria-label="download as csv">
              <FileDownloadOutlinedIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={!liveMode ? "Live mode" : "Pause live mode"}>
            <IconButton sx={{mr: 2}} onClick={() => toggleMode()} aria-label="clear filter">
              {!liveMode ? <PlayCircleOutlinedIcon /> : <PauseCircleOutlinedIcon />}
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};
