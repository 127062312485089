import React, {ReactNode} from "react";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import "./ConfirmationModal.scss";
import {Button, DialogActions, Divider} from "@mui/material";

interface ConfirmationModalProps {
  loading: boolean;
  title: ReactNode;
  text: ReactNode;
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {show, onClose, onConfirm, title, text, loading} = props;

  return (
    <Dialog className="dialog-container" open={show} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className="dialog-title">
        {title}
        {onClose && (
          <IconButton className="close-button" aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <div className="confirmation-text">{text}</div>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} className="no-text-transform" variant="contained" onClick={onConfirm}>
          Confirm
        </Button>
        <Button disabled={loading} className="no-text-transform" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
