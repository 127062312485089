import {VerificationStatusColor} from "./types";
import * as _ from "lodash";

export interface IEnhancedStatus {
  name: string;
  color: VerificationStatusColor;
}

export const getEnhancedStatus = (status: string, status_detail: string): IEnhancedStatus => {
  if (status === "PASSED") {
    return {
      name: "Passed",
      color: "success",
    };
  }

  if (status === "IN_PROGRESS") {
    return {
      name: "In Progress",
      color: "warning",
    };
  }

  if (status === "IN_PROGRESS_RETRY") {
    return {
      name: "In Progress Retry",
      color: "warning",
    };
  }

  if (status === "FAILED") {
    return {
      name: _.startCase(_.toLower(status_detail)),
      color: "error",
    };
  }

  if (status === "LOCKED") {
    return {
      name: _.startCase(_.toLower(status_detail)),
      color: "default",
    };
  }

  return {
    name: "Unknown",
    color: "default",
  };
};
