import {TableInstance} from "react-table";
import React from "react";
import "flatpickr/dist/themes/light.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import "./styles.scss";
import {Box} from "@mui/material";

export const DateRangeFilter = (props: TableInstance) => {
  const {
    column: {filterValue, setFilter, preFilteredRows, id},
    gotoPage,
  } = props;

  const dates = preFilteredRows.map((val: any) => moment(val.original[id]));
  const minDate = moment.min(dates).subtract(1, "day"); // To include the date
  const maxDate = moment.max(dates).add(1, "day");

  return (
    <Box style={{marginRight: 30}}>
      <Flatpickr
        value={filterValue}
        //@ts-ignore
        style={{
          height: "36px",
          minWidth: "170px",
          width: "100%",
          borderColor: "#bbbbbb",
          borderStyle: "solid",
          borderRadius: "4px",
          borderWidth: "1px",
          paddingLeft: "14px",
          paddingRight: "14px",
          fontSize: "1rem",
        }}
        onChange={(date) => {
          if (date.length === 2) {
            gotoPage(0);
            setFilter([date[0], date[1]]);
          } else {
            setFilter(undefined);
          }
        }}
        options={{
          enable: [
            {
              from: minDate.toDate(),
              to: maxDate.toDate(),
            },
          ],
          mode: "range",
        }}
      />
    </Box>
  );
};
