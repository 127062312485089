export const VERIFICATION_STATUS = {
  IN_PROGRESS: "In Progress",
  IN_PROGRESS_RETRY: "In Progress Retry",
  HARD_FAIL: "Hard Fail",
  SOFT_FAIL: "Soft Fail",
};
export enum NotificationTypes {
  EMAIL = "EMAIL",
  SMS = "SMS",
  CX = "CX",
  CLIENT = "CLIENT",
}

export enum VerificationStatus {
  IN_PROGRESS = "IN_PROGRESS",
  IN_PROGRESS_RETRY = "IN_PROGRESS_RETRY",
  PASSED = "PASSED",
  FAILED = "FAILED",
}

export enum StepNames {
  //inital states
  REQUESTED = "REQUESTED",
  NOTIFIED = "NOTIFIED",
  NOTIFIED_SMS = "NOTIFIED_SMS",
  CX = "CX", //fake step just too display in history correctly
  CLIENT = "ADMIN", //fake step just too display in history correctly

  //user interaction steps
  WELCOME = "WELCOME",
  EXTRA_INFORMATION = "EXTRA_INFORMATION",
  IDIQ_REQUEST = "IDIQ_REQUEST",
  DIFFERENTIATOR = "DIFFERENTIATOR",
  QUESTIONS = "QUESTIONS",
  CHALLENGE_REQUEST = "CHALLENGE_REQUEST",
  CHALLENGE = "CHALLENGE",
  ID_SERVICE_ERROR = "ID_SERVICE_ERROR",
  ERROR = "ERROR",

  //final steps
  SUCCESS = "SUCCESS",
  MANUAL_SUCCESS = "MANUAL_SUCCESS",
  SOFT_FAIL = "SOFT_FAIL",
  HARD_FAIL = "HARD_FAIL",
  MANUAL_CANCEL = "MANUAL_CANCEL",
  IGNORED_COUNTRY_NOT_ACTIVE = "IGNORED_COUNTRY_NOT_ACTIVE",
  IGNORED_CHANNEL_NOT_ACTIVE = "IGNORED_CHANNEL_NOT_ACTIVE",
  IGNORED_THRESHOLD_NOT_DEFINED = "IGNORED_THRESHOLD_NOT_DEFINED",
  IGNORED_THRESHOLD_NOT_REACHED = "IGNORED_THRESHOLD_NOT_REACHED",
  IGNORED_RESERVATION_NOT_FOUND = "IGNORED_RESERVATION_NOT_FOUND",
  IGNORED_RESERVATION_NOT_OK = "IGNORED_RESERVATION_NOT_OK",
  IGNORED_RESERVATION_CANCELLED = "IGNORED_RESERVATION_CANCELLED",
}
