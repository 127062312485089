import React from "react";
import {CircularProgress} from "@mui/material";
import "./Loading.scss";

export default function Loading() {
  return (
    <div className="loading-container">
      <CircularProgress size={60} />
    </div>
  );
}
