import React from "react";
import {FormControl, MenuItem, OutlinedInput, Select, SelectChangeEvent} from "@mui/material";
import {Row, TableInstance} from "react-table";
import {Theme, useTheme} from "@mui/material/styles";

const getStyles = (value: string, options: string[], theme: Theme) => {
  return {
    fontWeight: options.indexOf(value) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
};

export const MultiSelectFilter = (props: TableInstance) => {
  const theme = useTheme();

  const {
    column: {filterValue, setFilter, preFilteredRows, id},
    gotoPage,
  } = props;

  // Calculate the options for filtering
  // using the preFilteredRows
  const options: Array<string> = React.useMemo(() => {
    const options: Set<string> = new Set();
    preFilteredRows.forEach((row: Row) => {
      options.add(row.values[id]);
    });
    let newVar = [...options.values()];
    return newVar;
  }, [id, preFilteredRows]);

  const handleChange = (event: SelectChangeEvent<Array<string>>) => {
    const {
      target: {value},
    } = event;
    gotoPage(0);
    setFilter(typeof value === "string" ? [] : value);
  };

  return (
    <FormControl sx={{m: 0, minWidth: 170, width: "100%"}}>
      <Select multiple value={filterValue ?? []} onChange={handleChange} input={<OutlinedInput size="small" />}>
        {options.map((option, index) => (
          <MenuItem key={index} value={option} style={getStyles(option, options, theme)}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
