import React from "react";
import { Box } from "@mui/material";
import {getShortDate} from "../../../../utils/formats";

export interface IVerificationDetailHistoryProps {
    verification: any;
}
export default function VerificationDetailHistory(props: IVerificationDetailHistoryProps) {
    const {verification} = props;

    return (
        <Box className="section-box" height="100%">
            <Box className="section-title" style={{height: 32, display: "flex", alignItems: "flex-end"}}>
                History
            </Box>

            <Box className="section-divider" />
            <Box style={{height: "100%"}}>
                {/*, height: 667*/}
                <Box className="history-row">
                    <Box className="history-date-column bold">Date</Box>
                    <Box className="history-action-column bold">Action</Box>
                    <Box className="history-detail-column bold">Step/Detail</Box>
                </Box>
                {verification?.history &&
                    verification.history.map((el: any, index: number) => {
                        return (
                            <Box className="history-row" key={index}>
                                <Box className="history-date-column">{getShortDate(el.gen_created_at)}</Box>
                                <Box className="history-action-column">{el.short_name}</Box>
                                <Box className="history-detail-column">{el.detail}</Box>
                            </Box>
                        );
                    })
                }
            </Box>
        </Box>
    );
}
