import {useCallback} from "react";
import {getChannelByAdminId} from "../../../../../services/idv.service";

const useRefreshChannel = (channelId: number | undefined, setChannel: (channel: any) => void) => {
  const refreshChannel = useCallback(async () => {
    if (channelId === undefined) {
      return;
    }
    try {
      const {data: channel} = await getChannelByAdminId(channelId);
      const channelName = channel
        ? channel.code !== channel.name
          ? channel.code + " - " + channel.name
          : channel.code
        : "";
      setChannel({...channel, prettyName: channelName});
    } catch (err) {
      console.error("Error fetching channel");
    }
  }, [channelId, setChannel]);
  return refreshChannel;
};
export default useRefreshChannel;
