import React, {Dispatch, SetStateAction} from "react";
import {Box} from "@mui/material";
import {LoadFile} from "../LoadFile/LoadFile";
import {Modal} from "../Modal/Modal";
import {
    GoodBehaviourAllowListItem,
    ICSVFileItem
} from "../../../../views/GoodBehaviourAllowList/GoodBehaviourAllowList.types";

export interface IHeaderProps {
    isSaving: boolean;
    openGuestModal: boolean;
    newGuestBulk: Array<ICSVFileItem>;
    currentList: Array<GoodBehaviourAllowListItem>;
    setNewGuestBulk: Dispatch<SetStateAction<Array<ICSVFileItem>>>;
    setOpenGuestModal: Dispatch<SetStateAction<boolean>>;
    onSaveBulk: () => Promise<void>;
}
export const Header: React.FC<IHeaderProps> = ({newGuestBulk, setNewGuestBulk, onSaveBulk, openGuestModal, setOpenGuestModal, currentList, isSaving}) => {
    const onClose = () => {
        setOpenGuestModal(false);
    };

    return (
        <Box className="unit-allow-list-header">
            <Box>
                <b>Good Behaviour Allow List</b>
            </Box>
            <LoadFile setNewGuestBulk={setNewGuestBulk} setOpenGuestModal={setOpenGuestModal} currentList={currentList} openGuestModal={openGuestModal}/>
            <Modal openGuestModal={openGuestModal} onClose={onClose} newGuestBulk={newGuestBulk} onSaveBulk={onSaveBulk} isSaving={isSaving}/>
        </Box>
    );
}
