import {useCallback, useEffect, useState} from "react";
import {VerifiedUserInterface} from "./PassedValidationAllowList.types";
import {getVerifiedUsers} from "../../services/idv.service";
import * as _ from "lodash";

export function usePassedValidationAllowList(props: any) {
  const { queryParameters, setPaginationMeta } = props;
  const [verifiedUsers, setVerifiedUsers] = useState<VerifiedUserInterface[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadVerifiedUsers = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = (await getVerifiedUsers(queryParameters)).data;
      const newVerifiedUsers = _.orderBy(response.data ?? [], "updated_at", "desc");

      setPaginationMeta(response.meta)
      setVerifiedUsers(newVerifiedUsers);

    } catch (e) {
      console.error("Error getting Verified Users.");
    } finally {
      setIsLoading(false);
    }
  }, [setVerifiedUsers, setPaginationMeta, setIsLoading, queryParameters]);

  useEffect(() => {
    loadVerifiedUsers();
  }, [loadVerifiedUsers, queryParameters]);

  useEffect(() => {
  }, [queryParameters])

  return {
    verifiedUsers,
    isLoading,
    loadVerifiedUsers,
  };
}
