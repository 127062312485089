import React from "react";
import {TableBody as MaterialTableBody, TableCell, TableRow} from "@mui/material";
import {Row, TableBodyPropGetter, TableBodyProps} from "react-table";

interface TableBodyComponentProps {
  getTableBodyProps: (propGetter?: TableBodyPropGetter<any>) => TableBodyProps;
  page: Row<any>[];
  prepareRow: (row: Row<any>) => void;
  emptyRows: number;
  rowHeight: number;
  colSpan: number;
}

export const TableBody = (props: TableBodyComponentProps) => {
  return (
    <MaterialTableBody {...props.getTableBodyProps()}>
      {props.page.map((row) => {
        props.prepareRow(row);
        return (
          <TableRow {...row.getRowProps()}>
            {row.cells.map((cell) => {
              return (
                <TableCell {...cell.getCellProps()} style={{textAlign: "center", padding: "4px"}}>
                  {cell.render("Cell")}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
      {props.emptyRows > 0 && (
        <TableRow style={{height: props.rowHeight * props.emptyRows}}>
          <TableCell colSpan={props.colSpan} />
        </TableRow>
      )}
    </MaterialTableBody>
  );
};
