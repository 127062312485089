import React from "react";
import {Box, Button, Chip, Grid, TextField} from "@mui/material";
import "./unit-allow-list.component.scss";
import {useUnitAllowList} from "./unit-allow-list.component.hooks";

export const UnitAllowListComponent = () => {
  const {
    currentUnits,
    addedUnits,
    removedUnits,
    filter,
    addUnitsInput,
    hasChanges,
    onRemoveUnit,
    onRemoveRemovedUnit,
    onRemoveAddedUnit,
    onReset,
    onSave,
    unitFilter,
    onChangeFilter,
    onChangeAddUnitInput,
    onAddUnit,
    onKeyPressAddUnit,
    isLoading,
  } = useUnitAllowList();

  return (
    <Box className="container-view">
      <Box className="container-with-border">
        <Box className="container-with-bottom-border">
          <Box className="unit-allow-list-header">
            <Box>
              <b>Allow list management</b>
            </Box>
            <Box>
              <Button
                className="no-text-transform"
                variant="contained"
                color="inherit"
                onClick={() => onReset()}
                disabled={!hasChanges || isLoading}
              >
                Reset
              </Button>
              <Button
                className="no-text-transform ml-8"
                variant="contained"
                onClick={onSave}
                disabled={!hasChanges || isLoading}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className="container-body">
          <Box className="container-add-unit">
            <Box className="input-label">Add Unit</Box>
            <TextField
              placeholder="Enter the unit. You can add  multiple units separated by commas"
              size="small"
              fullWidth
              value={addUnitsInput}
              onKeyDown={onKeyPressAddUnit}
              onChange={onChangeAddUnitInput}
            ></TextField>
            <Button
              className="no-text-transform add-unit-button"
              variant="contained"
              onClick={onAddUnit}
              disabled={isLoading}
            >
              Add Unit to Allow List
            </Button>
          </Box>
          <Box className="container-with-bottom-border"></Box>
          <Box className="container-add-unit">
            <Box className="input-label">Search Allow Unit</Box>
            <TextField
              placeholder="Write your search"
              size="small"
              fullWidth
              value={filter}
              onChange={onChangeFilter}
            ></TextField>
            <Button className="add-unit-button visibility-hidden" variant="contained"></Button>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Box className="container-unit-allow-list container-with-border">
                {addedUnits.filter(unitFilter).map((item) => (
                  <Chip
                    key={item.name}
                    className="unit-chip"
                    color="success"
                    label={item.name}
                    onDelete={() => onRemoveAddedUnit(item)}
                  />
                ))}
                {currentUnits.filter(unitFilter).map((item) => (
                  <Chip key={item.name} className="unit-chip" label={item.name} onDelete={() => onRemoveUnit(item)} />
                ))}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className="container-unit-allow-list container-with-border">
                {removedUnits.filter(unitFilter).map((item) => (
                  <Chip
                    key={item.name}
                    className="unit-chip"
                    color="error"
                    label={item.name}
                    onDelete={() => onRemoveRemovedUnit(item)}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
          <Box className="count-info">
            Total: {currentUnits.length + addedUnits.length}, Added: {addedUnits.length}, Removed: {removedUnits.length}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
