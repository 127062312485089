import * as React from "react";
import {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  LinearProgress
} from "@mui/material";
import "./styles.scss";
import {VerificationDetailHeader} from "./VerificationDetailHeader";
import useRefreshReservation from "./hooks/useRefreshReservation";
import useRefreshChannel from "./hooks/useRefreshChannel";
import useRefreshVerification from "./hooks/useRefreshVerification";
import "react-phone-number-input/style.css";
import {ReservationType} from "../../types";
import useVerificationLock from "./hooks/useVerificationLock";
import {canAccess} from "../../../../utils/permissions";
import {VerificationDetailActions} from "./VerificationDetailActions";
import useRefreshReasons from "./hooks/useRefreshReasons";
import {VerificationDetailInformation} from "./VerificationDetailInformation";
import VerificationDetailHistory from "./VerificationDetailHistory";


const DialogContainer = styled(Dialog)(({theme}) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
}));

export interface IModalItem {
  verificationId: number | undefined;
  reservationId: number | undefined;
  channelId: number | undefined;
}

export interface IVerificationDetailProps {
  open: boolean;
  onClose: () => void;
  onListRefresh: () => Promise<void>;
  modalItem?: IModalItem;
}

export const VerificationDetail = (props: IVerificationDetailProps) => {
  //MODAL
  const {open, onClose, onListRefresh, modalItem} = props;
  const [loading, setLoading] = useState(false);

  //DATA
  const [reservation, setReservation] = useState<ReservationType | undefined>(undefined);
  const [verification, setVerification] = useState<any | undefined>(undefined);
  const [channel, setChannel] = useState<any>(undefined);
  const [reasons, setReasons] = useState<any[]>([]);

  //FORM
  const [actionLoading, setActionLoading] = useState(false);

  //CUSTOM HOOKS
  const refreshChannel = useRefreshChannel(modalItem?.channelId, setChannel);
  const refreshReservation = useRefreshReservation(modalItem?.reservationId, setReservation);
  const {lock, refreshLock, removeLock} = useVerificationLock(modalItem?.verificationId);
  const refreshVerification = useRefreshVerification(modalItem?.verificationId, setVerification);
  const refreshReasons = useRefreshReasons(setReasons);

  //GENERAL VALIDATIONS
  const disabledActions = actionLoading;
  const canVerificationActions = canAccess("verification-actions");

  const resetStates = () => {
    setActionLoading(false);
    setLoading(false);
  };

  const resetData = () => {
    setReservation(undefined);
    setChannel(undefined);
    setVerification(undefined);
    setReasons([]);
  };

  const onOpenModal = async () => {
    resetStates();
    resetData();
    setLoading(true);
    await Promise.all([refreshLock(), refreshVerification(), refreshReservation(), refreshChannel(), refreshReasons()]);
    setLoading(false);
  };

  const onCloseModal = () => {
    removeLock();
    onClose();
  };

  useEffect(() => {
    if (open) onOpenModal();
    // eslint-disable-next-line
  }, [open]);

  return (
    <Box>
      <DialogContainer open={open} onClose={onCloseModal} maxWidth="xl" fullWidth>
        <VerificationDetailHeader id="dialog-header" onClose={onCloseModal}>
          <Box className="dialog-header">
            Process Details{" "}
            {lock?.isLocked && (
              <Alert severity="error">
                Verification locked by: <strong>{lock?.lockedBy} </strong>
              </Alert>
            )}
          </Box>
        </VerificationDetailHeader>
        <Box sx={{width: "100%"}}>{actionLoading ? <LinearProgress /> : <Box height="4px" />}</Box>
        <DialogContent dividers>
          {loading ? (
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: 800}}>
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={2} alignItems="stretch">
              <Grid item xs={12} md={4} display="flex" flexDirection="column">
                  <VerificationDetailInformation
                    verification={verification}
                    reservation={reservation}
                    channel={channel}
                  />
              </Grid>

              {canVerificationActions && (
                <Grid item xs={12} md={8} display="flex" flexDirection="column">
                  <Box height="100%" className="section-box">
                    <Box className="nunito" style={{fontSize: 18, height: 32, display: "flex", alignItems: "flex-end"}}>
                      Actions
                    </Box>
                    <Box className="section-divider" />
                    <VerificationDetailActions
                        verification={verification}
                        isReservationCancelled={reservation?.attributes?.cancelled}
                        setActionLoading={setActionLoading}
                        onListRefresh={onListRefresh}
                        onCloseModal={onCloseModal}
                        disabledActions={disabledActions}
                        refreshVerification={refreshVerification}
                        lock={lock}
                        actionLoading={actionLoading}
                        reasons={reasons}
                        setReasons={setReasons}
                    ></VerificationDetailActions>
                  </Box>
                </Grid>
              )}

              <Grid item xs={12} md={canVerificationActions ? 12 : 8} display="flex" flexDirection="column">
                <VerificationDetailHistory verification={verification} />
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </DialogContainer>
    </Box>
  );
};
