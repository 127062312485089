import React, {useCallback, useMemo, useState} from "react";
import "./PassedValidationAllowList.scss";
import {Box, Grid, Table, TableCell, TableContainer, TableRow} from "@mui/material";
import {getFullDate} from "../../utils/formats";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import {TableOptions, useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import {usePassedValidationAllowList} from "./usePassedValidationAllowList";
import {VerifiedUserInterface} from "./PassedValidationAllowList.types";
import {TableToolbar} from "../../modules/common/components/Table/TableToolbar/TableToolbar";
import {GlobalFilter} from "../../modules/common/components/Filters/GlobalFilter/GlobalFilter";
import {TableHead} from "../../modules/common/components/Table/TableHead/TableHead";
import {TableBody} from "../../modules/common/components/Table/TableBody/TableBody";
import {ConfirmationModal} from "../../modules/common/components/ConfirmationModal/ConfirmationModal";
import {deleteVerifiedUsers} from "../../services/idv.service";
import {LinearLoading} from "../../modules/common/components/LinearLoading/LinearLoading";
import { TableFooterPagination } from "../../modules/common/components/Table/TableFooter/TableFooterPagination";
import { useCustomPagination } from "../../hooks/useCustomPagination";

const ROW_HEIGHT = 49;

export const PassedValidationAllowList = () => {
    const {
        setSearch,
        setOrderBy,
        setNextPage,
        setPageLimit,
        paginationMeta,
        queryParameters,
        setPreviousPage,
        setPaginationMeta,
        setFirstOrLastPage,
        handleOnChangeFilter,
    } = useCustomPagination();

    const {
        isLoading,
        verifiedUsers,
        loadVerifiedUsers,
    } = usePassedValidationAllowList({queryParameters, setPaginationMeta});

  const [isDeleting, setIsDeleting] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentVerifiedUser, setCurrentVerifiedUser] = useState<VerifiedUserInterface | undefined>(undefined);

  const onShow = useCallback(
    async (verifiedUser: VerifiedUserInterface) => {
      setCurrentVerifiedUser(verifiedUser);
      setShowConfirmationModal(true);
    },
    [setCurrentVerifiedUser, setShowConfirmationModal]
  );

  const onCloseConfirmation = useCallback(async () => {
    setShowConfirmationModal(false);
  }, [setShowConfirmationModal]);

  const onConfirm = useCallback(async () => {
    try {
      setIsDeleting(true);

      await deleteVerifiedUsers(Number(currentVerifiedUser?.id));
      loadVerifiedUsers();

      setShowConfirmationModal(false);
    } catch (e) {
      console.error("Error while deleting verified user", e);
    } finally {
      setIsDeleting(false);
    }
  }, [currentVerifiedUser, loadVerifiedUsers, setShowConfirmationModal, setIsDeleting]);

  const columns = useMemo(
    () => [
      {
        accessor: "id",
        Header: "ID",
        width: "100%",
      },
      {
        accessor: "verification_id",
        Header: "Verification ID",
        width: "100%",
      },
      {
        accessor: "email",
        Header: "Email",
        width: "100%",
      },
      {
        accessor: "name",
        Header: "First name",
        width: "100%",
      },
      {
        accessor: "last_name",
        Header: "Last name",
        width: "100%",
      },
      {
        accessor: "created_at",
        Header: "Created at",
        width: "100%",

        Cell: (props: any) => {
          return <div>{getFullDate(props.row.original?.created_at)}</div>;
        },
      },
      {
        accessor: "updated_at",
        Header: "Updated at",
        width: "100%",

        Cell: (props: any) => {
          return <div>{getFullDate(props.row.original?.created_at)}</div>;
        },
      },
      {
        Header: "Actions",
        width: "100%",

        Cell: (props: any) => {
          return (
            <div className="actions">
              <IconButton onClick={() => onShow(props.row.original as VerifiedUserInterface)} aria-label="first page">
                <DeleteIcon />
              </IconButton>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    state: {pageIndex, pageSize, globalFilter},
    preGlobalFilteredRows,
  } = useTable(
    {
      columns,
      data: verifiedUsers,
      autoResetSortBy: false,
      autoResetPage: false,
    } as TableOptions<VerifiedUserInterface>,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const emptyRows = Math.max(0,  paginationMeta.limit) + 1;

  return (
    <div className="view-container">
      <div className="table-container">
        <ConfirmationModal
          loading={isDeleting || isLoading}
          title={"Confirmation"}
          text={`Are you sure you want to remove the user ${currentVerifiedUser?.email} from the passed validations list?`}
          show={showConfirmationModal}
          onClose={onCloseConfirmation}
          onConfirm={onConfirm}
        />
        <TableToolbar title="Passed Validation Allow List">
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box sx={{mr: 2}}>{isLoading ? "Loading..." : ""}</Box>
                <GlobalFilter
                    isDisabled={isLoading}
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={handleOnChangeFilter}
                    handleKeyDown={setSearch}
                    placeholder="Write and press enter to search"
                />
            </Grid>
        </TableToolbar>
        <TableContainer>
          <LinearLoading isLoading={isLoading} />
          <Table {...getTableProps()}>
              {
                  isLoading ?
                      <tbody>
                          <TableRow style={{height: 49 * emptyRows}}>
                              <TableCell colSpan={6} />
                          </TableRow>
                      </tbody>
                      :
                      <>
                          <TableHead headerGroups={headerGroups} />
                          <TableBody
                              getTableBodyProps={getTableBodyProps}
                              page={page}
                              emptyRows={Math.max(0, (1 + pageIndex) * pageSize - rows.length)}
                              prepareRow={prepareRow}
                              colSpan={columns.length}
                              rowHeight={ROW_HEIGHT}
                          />
                      </>
              }
            <TableFooterPagination
              canNextPage={paginationMeta.hasNextPage}
              canPreviousPage={paginationMeta.hasPreviousPage}
              colSpan={columns.length}
              gotoPage={gotoPage}
              headerGroups={headerGroups}
              nextPage={setNextPage}
              pageCount={pageCount}
              pageIndex={paginationMeta.page - 1}
              pageSize={paginationMeta.limit}
              previousPage={setPreviousPage}
              rows={paginationMeta.count}
              setFirstOrLastPage={setFirstOrLastPage}
              setPageLimit={setPageLimit}
              setPageSize={setPageSize}
              setOrderBy={setOrderBy}
              isLoading={isLoading}
            />
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
