import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogContent, DialogActions, Divider, Box} from "@mui/material";
import {
    ICSVFileItem
} from "../../../../views/GoodBehaviourAllowList/GoodBehaviourAllowList.types";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import "./../../../common/components/ConfirmationModal/ConfirmationModal.scss"; // reuse css

export interface IModalProps {
    openGuestModal: boolean;
    isSaving: boolean;
    newGuestBulk: Array<ICSVFileItem>;
    onSaveBulk: () => Promise<void>;
    onClose: () => void;
}
export const Modal: React.FC<IModalProps> = ({openGuestModal, onClose, newGuestBulk, onSaveBulk, isSaving}) => {
    const [valid, setValid] = useState(0);

    useEffect(() => {
        let aux = 0;
        for (const item of newGuestBulk) {
            if (item.valid) {
                aux++;
            }
        }
        setValid(aux);
    }, [newGuestBulk]);

    return (
        <Dialog className="dialog-container" open={openGuestModal} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className="dialog-title">
                New Guests
                {onClose && (
                    <IconButton className="close-button" aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                )}
            </DialogTitle>
            <Divider />
            <DialogContent>
                <p className="confirmation-text">Validation of valid and non-existent emails, only those that pass the validations will be saved.</p>
                <Box style={{border: "solid 1px #0000001f", maxHeight: 400, overflowY: "scroll", borderRadius: 6}}>
                    {newGuestBulk?.map((value: ICSVFileItem, index: number) => {
                        return (
                            <div key={index}>
                                {value.valid ? <CheckIcon color="success" style={{marginBottom: -5}} /> : <CloseIcon color="error" style={{marginBottom: -5}} />}
                                {value.email} {value?.name ? `(${value?.name})` : ""}
                            </div>
                        );
                    })}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button className="no-text-transform" variant="contained" onClick={onSaveBulk} disabled={isSaving || valid === 0}>
                    Save ({valid})
                </Button>
                <Button className="no-text-transform" variant="outlined" onClick={onClose} disabled={isSaving}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>

    );
}
