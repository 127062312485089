import React from "react";
import {TablePagination as MaterialTablePagination} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";

interface IVerificationTablePaginationProps {
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageCount: number;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (pageSize: number) => void;
  pageIndex: number;
  pageSize: number;
  rows: number;
}

export const VerificationTablePagination = (props: IVerificationTablePaginationProps) => {
  const {canPreviousPage, canNextPage, pageCount} = props;
  const {gotoPage, nextPage, previousPage, setPageSize, pageIndex, pageSize, rows} = props;
  const theme = useTheme();
  const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    gotoPage(page);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    gotoPage(0);
  };

  return (
    <MaterialTablePagination
      rowsPerPageOptions={[5, 10, 25, 50]}
      colSpan={6}
      count={rows}
      rowsPerPage={pageSize}
      page={pageIndex}
      SelectProps={{
        inputProps: {
          "aria-label": "rows per page",
        },
        native: true,
      }}
      onPageChange={onPageChange}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={() => {
        return (
          <Box sx={{flexShrink: 0, ml: 2.5}}>
            <IconButton onClick={() => gotoPage(0)} disabled={!canPreviousPage} aria-label="first page">
              {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={() => previousPage()} disabled={!canPreviousPage} aria-label="previous page">
              {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={() => nextPage()} disabled={!canNextPage} aria-label="next page">
              {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} aria-label="last page">
              {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
          </Box>
        );
      }}
    />
  );
};
