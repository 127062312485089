import React from "react";
import {Box} from "@mui/material";
import {VerificationTable} from "../../modules/verification/components/VerificationTable/VerificationTable";

export const Home = () => {
  return (
    <Box style={{marginTop: 16, marginBottom: 16, marginRight: 40, marginLeft: 40}}>
      <VerificationTable />
    </Box>
  );
};
