import React, {useEffect, useState} from "react";
import {
    Box, Button, LinearProgress, TextField
} from "@mui/material";
import "./GoodBehaviourAllowList.component.scss";
import "./../UnitAllowList/unit-allow-list.component.scss"; // reuse this css
import {useGoodBehaviourAllowList} from "./GoodBehaviourAllowList.component.hooks";
import {GoodBehaviourTable} from "../../modules/goodBehaviour/components/GoodBehaviourTable/GoodBehaviourTable";
import {Header} from "../../modules/goodBehaviour/components/Header/Header";
import {emailPattern} from "../../utils/patterns";
import Loading from "../../modules/common/components/Loading/Loading";
import {GoodBehaviourAllowListItem} from "./GoodBehaviourAllowList.types";
import {ConfirmationModal} from "../../modules/common/components/ConfirmationModal/ConfirmationModal";

export const GoodBehaviourAllowListComponent = () => {
    const {
        // LIST AND FLAGS
        currentList, isLoading, isSaving,
        // SAVE BULK
        newGuestBulk, setNewGuestBulk, onSaveBulk, openGuestModal, setOpenGuestModal,
        // SAVE SINGLE
        newGuest, setNewGuest, onSaveSingle,
        // DELETE SINGLE
        onDelete, guestToDelete, setGuestToDelete, openConfirmationModal, setOpenConfirmationModal
    } = useGoodBehaviourAllowList();

    const [isEmailValid, setIsEmailValid] = useState(false);


    const onChangeNewGuest = (event: {email?: string, name?: string}) => {
        setNewGuest({...newGuest, ...event});
    }

    const onDeleteConfirmation = (data: GoodBehaviourAllowListItem) => {
        setGuestToDelete(data);
        setOpenConfirmationModal(true);
    }

    useEffect(() => {
        const hasValidFormat = emailPattern.test(newGuest.email);
        const isNotDuplicated = !currentList.find((item: GoodBehaviourAllowListItem) => item.email === newGuest.email.toLowerCase());
        setIsEmailValid(hasValidFormat && isNotDuplicated);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newGuest.email]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Box className="container-view" style={{paddingBottom: 30}}>
            <Box className="container-with-border">
                <Box className="container-with-bottom-border">
                    <Header newGuestBulk={newGuestBulk} setNewGuestBulk={setNewGuestBulk} onSaveBulk={onSaveBulk}
                            openGuestModal={openGuestModal} setOpenGuestModal={setOpenGuestModal}
                            currentList={currentList} isSaving={isSaving}/>
                </Box>
                <Box sx={{width: "100%"}}>{isSaving ? <LinearProgress/> : <Box height="4px"/>}</Box>
                <Box className="container-body-override">
                    <Box className="container-add-unit container-with-margin">
                        <Box className="input-label">Email</Box>
                        <TextField
                            placeholder="Add the guest email"
                            size="small"
                            fullWidth
                            value={newGuest.email}
                            onChange={(event: any) => onChangeNewGuest({email: event.target.value})}
                            error={!isEmailValid && newGuest.email !== ""}
                            helperText={!isEmailValid && newGuest.email !== "" ? "Incorrect email format or duplicated" : " "}
                            style={{marginTop: 20}}
                        ></TextField>
                        <Box className="input-label" style={{marginLeft: 30}}>Name</Box>
                        <TextField
                            placeholder="Add the guest name (optional)"
                            size="small"
                            fullWidth
                            value={newGuest.name}
                            onChange={(event: any) => onChangeNewGuest({name: event.target.value})}
                        ></TextField>
                        <Button
                            className="no-text-transform add-unit-button"
                            variant="contained"
                            onClick={onSaveSingle}
                            disabled={isSaving || !isEmailValid}
                        >
                            Add Guest
                        </Button>
                        <Box className="container-with-bottom-border"></Box>
                    </Box>

                    <Box className="container-with-bottom-border-light container-with-margin"></Box>

                    <GoodBehaviourTable data={currentList} onDeleteConfirmation={onDeleteConfirmation}/>
                    <ConfirmationModal
                        onConfirm={onDelete}
                        show={openConfirmationModal}
                        onClose={() => setOpenConfirmationModal(false)}
                        title="Confirmation"
                        text={`Are you sure you want to remove the quest ${guestToDelete.email} from the good behavior list? `}
                        loading={isSaving}
                    />
                </Box>
            </Box>
        </Box>
    );
};
