import React, {useEffect, useState} from "react";
import {Alert, AlertTitle, Box} from "@mui/material";
import * as _ from "lodash";
import {getVerificationByReservation} from "../../services/idv.service";
import {useParams} from "react-router-dom";
import Loading from "../../modules/common/components/Loading/Loading";
import VerificationDetailContent from "../../modules/verification/components/VerificationDetail/VerificationDetailContent";

export default function VerificationDetailsView() {
  const {reservationId} = useParams();
  const [loading, setLoading] = useState(true);
  const [found, setFound] = useState(false);
  const [state, setState] = useState({verificationId: 0, channelId: 0});

  const loadScreen = async () => {
    if (!_.isNil(reservationId)) {
      const verifications = await getVerificationByReservation(+reservationId);

      if (!_.isEmpty(verifications)) {
        const verification = verifications.data?.pop();
        setState({
          verificationId: verification?.id || 0,
          channelId: verification?.data?.channel_id || 0,
        });

        setFound(true);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    loadScreen();
    //eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (!loading && !found) {
    return (
      <Box sx={{margin: "32px"}}>
        <Alert severity="error" variant="filled">
          <AlertTitle>Error</AlertTitle>
          Reservation not found
        </Alert>
      </Box>
    );
  }

  return (
      <VerificationDetailContent
        reservationId={reservationId ? +reservationId : 0}
        verificationId={state.verificationId}
        channelId={state.channelId}
      />
  );
}
