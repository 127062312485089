import React from "react";
import ReactDOM from "react-dom";
import {App} from "./App";
import "./index.scss";
import {FrontendConfiguration} from "./config/frontend.config";
import {ImplicitFlow} from "@vacasa/react-components-lib";

if (FrontendConfiguration.isLocalEnv()) {
  console.log(process.env);
}

const params = new URLSearchParams(window.location.search);
const access_token: string | null = params.get("token");
if (access_token) {
  localStorage.setItem("access_token", access_token);
}

const implicitFlowConfig = {
  ...FrontendConfiguration.implicitFlowConfig(),
  tokenRefresh: false,
  followRedirect: true,
};

window.addEventListener(
  "message",
  (event) => {
    if (event.origin !== FrontendConfiguration.getLegacyAngularAppUrl()) {
      return;
    }
    const token = event.data.token;
    const permissions = event.data.permissions;
    if (token !== undefined && token !== "") {
      localStorage.setItem("access_token", token);
      localStorage.setItem("permissions", permissions);
    }
  },
  false
);

ReactDOM.render(
  <React.StrictMode>
    <ImplicitFlow {...implicitFlowConfig}>
      <App />
    </ImplicitFlow>
  </React.StrictMode>,
  document.getElementById("root")
);
