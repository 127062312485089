import {useCallback} from "react";
import {getReservation} from "../../../../../services/idv.service";

const useRefreshReservation = (reservationId: number | undefined, setReservation: (reservation: any) => void) => {
  const refreshReservation = useCallback(async () => {
    if (reservationId === undefined) {
      setReservation(undefined);
      return;
    }
    try {
      const newReservation = await getReservation(reservationId);
      setReservation(newReservation.data);
    } catch (e) {
      console.error("Error fetching reservation");
    }
  }, [reservationId, setReservation]);
  return refreshReservation;
};

export default useRefreshReservation;
