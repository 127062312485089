import {useCallback, useState} from "react";
import {addVerificationLock, removeVerificationLock} from "../../../../../services/idv.service";
import {LockType} from "../../../types";

const useVerificationLock = (verificationId: number | undefined) => {
  const [lock, setLock] = useState<(LockType & {isLocked: boolean}) | undefined>(undefined);

  const refreshLock = useCallback(async () => {
    if (verificationId === undefined) {
      return;
    }
    try {
      const responseLock = await addVerificationLock(verificationId);
      setLock({...responseLock.data, isLocked: !responseLock.data.success});
    } catch (err) {
      console.error("Error Verification Lock");
    }
  }, [verificationId]);

  const removeLock = useCallback(async () => {
    if (verificationId === undefined) {
      return;
    }
    try {
      await removeVerificationLock(verificationId);
      setLock(undefined);
    } catch (err) {
      console.error("Error Verification Lock");
    }
  }, [verificationId]);

  return {lock, refreshLock, removeLock};
};

export default useVerificationLock;
