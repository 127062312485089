import {Box, Skeleton} from "@mui/material";
import {VerificationStatus} from "../VerificationStatus/VerificationStatus";
import {FrontendConfiguration} from "../../../../config/frontend.config";
import * as React from "react";
import {ReactNode} from "react";

const Detail = (props: {title: ReactNode; value: ReactNode; loading?: boolean}) => {
    return (
        <Box display="flex" flexDirection="row" style={{marginTop: 6}}>
            <Box minWidth={130} style={{fontSize: 13}}>
                {props.title}
            </Box>

            <Box style={{overflowWrap: "break-word", fontSize: 13, width: "100%"}}>
                {!props.loading ? props.value : <Skeleton variant="text" />}
            </Box>
        </Box>
    );
};

export interface IVerificationDetailInformation {
    verification: any;
    reservation: any;
    channel: any;
}
export const VerificationDetailInformation = (props: IVerificationDetailInformation ) => {
    const {verification, reservation, channel} = props;

    return (
        <Box height="100%">
        <Box className="section-box" style={{marginBottom: 16}}>
            <Box
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                }}
            >
                <Box>
                    <Box className="section-title">Verification id {verification?.verification?.id}</Box>
                    <Box className="detail">{verification?.uuid}</Box>
                </Box>
                <VerificationStatus
                    label={verification?.status ?? "Unknown"}
                    color={verification?.status_color ?? "default"}
                />
            </Box>

            <Box className="section-divider" />
            <Detail title="VERIFIYING" value={verification?.guest_name} />
            <Detail title="REQUESTED" value={verification?.client_requested_at} />
            <Detail title="CREATED" value={verification?.created_at} />
            <Detail title="UPDATED" value={verification?.updated_at} />
        </Box>

        <Box className="section-box">
            <Box className="section-title">Reservation details</Box>
            <Box className="section-divider" />

            <Detail
                title="RESERVATION"
                value={
                    <Box>
                        <a
                            href={FrontendConfiguration.getReseditLink(verification?.client_id)}
                            target="_blank"
                            rel="noreferrer"
                            style={{textDecoration: "none", color: "#337ab7"}}
                        >
                            {verification?.client_id}
                        </a>
                        <Box component="span">{reservation?.attributes?.cancelled && "(Cancelled)"}</Box>
                    </Box>
                }
            />
            <Detail
                title="GUEST NAME"
                value={
                    <Box>
                        {reservation?.attributes?.first_name} {reservation?.attributes?.last_name}
                    </Box>
                }
            />

            <Detail title="GUEST EMAIL" value={<Box>{verification?.verification?.email}</Box>} />
            <Detail
                title="GUEST PHONE"
                value={<Box>{reservation?.attributes?.phone || reservation?.attributes?.phone_2 || ""}</Box>}
            />
            <Detail
                title="CHANNEL"
                value={
                    <Box>
                        {channel?.prettyName}
                        {verification?.data?.channel_ref_id ? ` #${verification?.data?.channel_ref_id}` : ""}
                    </Box>
                }
            />
        </Box>
    </Box>
    );
}
