import React, { useEffect } from "react";
import {
    Box,
    TableFooter as MaterialTableFooter,
    TablePagination as MaterialTablePagination,
    TableRow,
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { HeaderGroup } from "react-table";

interface TableFooterProps {
    canPreviousPage: boolean;
    canNextPage: boolean;
    pageCount: number;
    gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
    nextPage: () => void;
    previousPage: () => void;
    setPageSize: (pageSize: number) => void;
    pageIndex: number;
    pageSize: number;
    rows: number;
    colSpan: number;
    setPageLimit: (pageSize: number) => void;
    setFirstOrLastPage: (pageIndex: number) => void;
    headerGroups: HeaderGroup<any>[];
    setOrderBy: (headerGroups: HeaderGroup<any>[]) => void;
    isLoading: boolean;
}

export const TableFooterPagination = (props: TableFooterProps) => {
    const theme = useTheme();

    const headerGroups = props.headerGroups[0].getHeaderGroupProps();
    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        props.gotoPage(page);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const pageSize = parseInt(event.target.value, 10);
        props.setPageSize(pageSize);
        props.setPageLimit(pageSize);
        props.gotoPage(0);
    };

    const handleGoToPage = (pageIndex: number) => {
        props.setFirstOrLastPage(pageIndex);
        props.gotoPage(pageIndex)
    }

    useEffect(() => {
        props.setOrderBy(props.headerGroups);
    }, [props, headerGroups]);

    return (
        <MaterialTableFooter>
            <TableRow>
                <MaterialTablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    colSpan={props.colSpan}
                    count={props.rows}
                    rowsPerPage={props.pageSize}
                    page={props.pageIndex}
                    SelectProps={{
                        inputProps: {
                            "aria-label": "rows per page",
                        },
                        native: true,
                    }}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={() => {
                        return (
                            <Box sx={{flexShrink: 0, ml: 2.5}}>
                                <IconButton onClick={() =>
                                    handleGoToPage(0)}
                                            disabled={!props.canPreviousPage || props.isLoading}
                                            aria-label="first page"
                                >
                                    {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
                                </IconButton>
                                <IconButton
                                    onClick={() => props.previousPage()}
                                    disabled={!props.canPreviousPage || props.isLoading}
                                    aria-label="previous page"
                                >
                                    {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                </IconButton>
                                <IconButton onClick={() => props.nextPage()} disabled={!props.canNextPage || props.isLoading} aria-label="next page">
                                    {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                </IconButton>
                                <IconButton
                                    onClick={() => handleGoToPage(props.rows)}
                                    disabled={!props.canNextPage || props.isLoading}
                                    aria-label="last page"
                                >
                                    {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
                                </IconButton>
                            </Box>
                        );
                    }}
                />
            </TableRow>
        </MaterialTableFooter>
    );
};
