import {useEffect, useState} from "react";
import {getAllVerifications, getListLocks} from "../../../../../services/idv.service";
import {VerificationItem} from "../../../types";
import useLocalStorage from "../../../../../hooks/useLocalStorage";
import useInterval from "../../../../../hooks/useInterval";
import {getEnhancedStatus} from "../../../utils";
import {getFullDateWithTZ} from "../../../../../utils/formats";

const verificationMapper = (el: any): VerificationItem => {
  const enhancedStatus = getEnhancedStatus(el?.status, el?.status_detail);
  const check_in = el?.expires_at ?? el?.data?.first_night ?? undefined;
  const timezone = el?.data?.timezone;

  return {
    id: el?.id,
    created_at: el?.created_at,
    reservation_id: el?.data?.reservation_id,
    channel: el?.data?.channel ?? "-",
    check_in: check_in,
    check_in_tz: check_in ? getFullDateWithTZ(check_in, timezone) : undefined,
    status: enhancedStatus.name,
    status_color: enhancedStatus.color,
    channel_id: el?.data?.channel_id,
    isLock: false,
    timezone: timezone
  };
};

const dateCompare = (a: VerificationItem, b: VerificationItem) => {
  return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
};

const dataTransform = (data: Array<any>, locks: Array<string>): Array<VerificationItem> => {
  return data
    .map(verificationMapper)
    .map((el) => ({...el, isLock: locks.includes(`${el.id}`)}))
    .sort(dateCompare);
};

const getVerificationIds = (verifications: Array<any>): Array<string> => {
  return verifications.map((verification) => `${verification.id}`);
};

const useVerificationsData = () => {
  const [data, setData] = useState<Array<VerificationItem>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [liveMode, setLiveMode] = useLocalStorage("live_mode", true);

  const refresh = async () => {
    try {
      setIsLoading(true);
      const responseVerifications = await getAllVerifications();
      const responseListLocks = await getListLocks(getVerificationIds(responseVerifications.data));
      const transformedData = dataTransform(responseVerifications.data ?? [], responseListLocks.data ?? []);
      setData(transformedData);
    } catch (e) {
      console.error("Error fetching verifications");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data.length === 0) {
      refresh();
    }
    // eslint-disable-next-line
  }, []);

  const toggleMode = () => {
    setLiveMode(!liveMode);
    if (!liveMode) {
      refresh();
    }
  };

  useInterval(
    () => {
      refresh();
    },
    liveMode ? 10000 : null
  );

  return {data, isLoading, refresh, liveMode, toggleMode};
};

export default useVerificationsData;
