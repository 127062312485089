import {useEffect, useState} from "react";
import {deleteGoodBehaviourGuest, getGoodBehaviourList, saveGoodBehaviourGuests} from "../../services/idv.service";
import {GoodBehaviourAllowListItem, ICSVFileItem} from "./GoodBehaviourAllowList.types";

export const useGoodBehaviourAllowList = () => {
  const [currentList, setCurrentList] = useState<Array<GoodBehaviourAllowListItem>>([]);
  const [newGuestBulk, setNewGuestBulk] = useState<Array<ICSVFileItem>>([]);
  const [newGuest, setNewGuest] = useState<GoodBehaviourAllowListItem>({id: -1, email: "", name: ""});
  const [guestToDelete, setGuestToDelete] = useState<GoodBehaviourAllowListItem>({id: -1, email: "", name: ""});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [openGuestModal, setOpenGuestModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const response = await getGoodBehaviourList() as any;
        const data = response.data;
        setCurrentList(data);
      } catch (e) {
        console.error("Error getting good behaviour list.");
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, []);

  const onSaveBulk = async () => {
    await onSave(newGuestBulk.filter((item: any) => item.valid));
    setNewGuestBulk([]);
    setOpenGuestModal(false);
  };

  const onSaveSingle = async () => {
    await onSave([{email: newGuest.email, name:newGuest.name}] as any);
    setNewGuest({id: -1, email: "", name: ""});
  };

  const onSave = async (payload: Array<{email: string, name?: string}>) => {
    try {
      setIsSaving(true);
      const response = await saveGoodBehaviourGuests(payload.map(item => ({email: item.email.toLowerCase(), name: item.name}))) as any;
      const data = response.data;
      setCurrentList(data);
    } catch (e) {
      console.error("Error saving changes.",e);
    } finally {
      setIsSaving(false);
    };
  };

  const onDelete = async () => {
    try {
      setIsSaving(true);
      const response = await deleteGoodBehaviourGuest(guestToDelete.id as number) as any;
      const data = response.data;
      setCurrentList(data);
      setOpenConfirmationModal(false);
    } catch (e) {
      console.error(`Error deleting guest, id: ${guestToDelete.id}, email: ${guestToDelete.email}`, e);
    } finally {
      setIsSaving(false);
    }
  };

  return {
    currentList,
    isLoading,
    newGuestBulk,
    setNewGuestBulk,
    onSaveBulk,
    newGuest,
    onSaveSingle,
    setNewGuest,
    guestToDelete,
    setGuestToDelete,
    onDelete,
    openGuestModal,
    setOpenGuestModal,
    isSaving,
    openConfirmationModal,
    setOpenConfirmationModal
  };
}
