import React, {useEffect, useState} from "react";
import {Alert, AlertTitle, Box, Grid} from "@mui/material";
import Loading from "../../../common/components/Loading/Loading";
import {ReservationType} from "../../types";
import useRefreshChannel from "./hooks/useRefreshChannel";
import useRefreshReservation from "./hooks/useRefreshReservation";
import useRefreshVerification from "./hooks/useRefreshVerification";
import useVerificationLock from "./hooks/useVerificationLock";
import {VerificationDetailActions} from "./VerificationDetailActions";
import useRefreshReasons from "./hooks/useRefreshReasons";
import {VerificationDetailInformation} from "./VerificationDetailInformation";
import {canAccess} from "../../../../utils/permissions";
import VerificationDetailHistory from "./VerificationDetailHistory";

export interface IVerificationDetailContentProps {
  verificationId: number;
  reservationId: number;
  channelId: number;
}

export default function VerificationDetailContent(props: IVerificationDetailContentProps) {
  const [loading, setLoading] = useState(true);
  //DATA
  const [reservation, setReservation] = useState<ReservationType | undefined>(undefined);
  const [verification, setVerification] = useState<any | undefined>(undefined);
  const [channel, setChannel] = useState<any>(undefined);

  //CUSTOM HOOKS
  const refreshChannel = useRefreshChannel(props?.channelId, setChannel);
  const refreshReservation = useRefreshReservation(props?.reservationId, setReservation);
  const {lock, refreshLock, removeLock} = useVerificationLock(props?.verificationId);
  const refreshVerification = useRefreshVerification(props?.verificationId, setVerification);

  //CUSTOM HOOKS //TODO: Refactor to:
  // const {channel, setChannel, refreshChannel} = useChannel(props.channelId);
  // const {reservation, setReservation, refreshReservation} = useReservation(props.reservationId);
  // const {lock, refreshLock, removeLock} = useVerificationLock(props.verificationId);
  // const {verification, setVerification, refreshVerification} = useVerification(props.verificationId);

  //FORM
  const [, setAction] = useState("");
  const [actionLoading, setActionLoading] = useState(false);
  const [reasons, setReasons] = useState<any[]>([]);

  //GENERAL VALIDATIONS
  const disabledActions = actionLoading;
  const canVerificationActions = canAccess("verification-actions");
  const refreshReasons = useRefreshReasons(setReasons);

  const resetStates = () => {
    setAction("");
    setActionLoading(false);
    setReasons([]);
    setLoading(false);
  };

  const resetData = () => {
    setReservation(undefined);
    setChannel(undefined);
    setVerification(undefined);
  };

  const onInit = async () => {
    resetStates();
    resetData();
    setLoading(true);
    await Promise.all([refreshLock(), refreshVerification(), refreshReservation(), refreshChannel(), refreshReasons()]);
    setLoading(false);
  };

  useEffect(() => {
    onInit();
    return () => {
      removeLock();
    };
    // eslint-disable-next-line
  }, []);


  if (loading) {
    return <Loading />;
  }

  if (!loading && !reservation) {
    return (
        <Box sx={{margin: "32px"}}>
          <Alert severity="error" variant="filled">
            <AlertTitle>Error</AlertTitle>
            Reservation not found
          </Alert>
        </Box>
    );
  }

  return (
      <Box sx={{margin: "10px"}}>
        <Grid container spacing={2} alignItems="stretch">
          {lock?.isLocked && (
              <Grid item xs={12} md={12} display="flex" flexDirection="column">
                <Alert severity="error">
                  Verification locked by: <strong>{lock?.lockedBy} </strong>
                </Alert>
              </Grid>
          )}

          <Grid item xs={12} md={4} display="flex" flexDirection="column">
            <VerificationDetailInformation
                verification={verification}
                reservation={reservation}
                channel={channel}
            />
          </Grid>
          {canVerificationActions && (
              <Grid item xs={12} md={8} display="flex" flexDirection="column">
                <Box height="100%" className="section-box">
                  <Box className="nunito" style={{fontSize: 18, height: 32, display: "flex", alignItems: "flex-end"}}>
                    Actions
                  </Box>
                  <Box className="section-divider"/>
                  <VerificationDetailActions
                      verification={verification}
                      isReservationCancelled={reservation?.attributes?.cancelled}
                      setActionLoading={setActionLoading}
                      onListRefresh={() => undefined}
                      onCloseModal={refreshVerification}
                      disabledActions={disabledActions}
                      refreshVerification={refreshVerification}
                      lock={lock}
                      actionLoading={actionLoading}
                      reasons={reasons}
                      setReasons={setReasons}
                  ></VerificationDetailActions>
                </Box>
              </Grid>
          )}

          <Grid item xs={12} md={canVerificationActions ? 12 : 8} display="flex" flexDirection="column">
            <VerificationDetailHistory verification={verification}/>
          </Grid>
        </Grid>
      </Box>
  );
}
