import React from "react";
import {Header} from "./modules/common/components/Header/Header";
import {Footer} from "./modules/common/components/Footer/Footer";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Home} from "./views/Home/Home";
import {Box, ThemeProvider} from "@mui/material";
import "./styles/base.module.scss";
import theme from "./theme";
import VerificationDetailsView from "./views/VerificationDetailsView/VerificationDetailsView";
import {UnitAllowListComponent} from "./views/UnitAllowList/unit-allow-list.component";
import {PassedValidationAllowList} from "./views/PassedValidationAllowList/PassedValidationAllowList";
import {GoodBehaviourAllowListComponent} from "./views/GoodBehaviourAllowList/GoodBehaviourAllowList.component";

export const App = () => {
  const containerHeight = document.documentElement.clientHeight;
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Box sx={{display: "flex", flexDirection: "column", height: "100vh", width: "100vw"}}>
          <Box sx={{display: "flex", flex: 1, overflowY: "auto", height: containerHeight}}>
            <Header />
            <Box sx={{display: "flex", flex: 1, flexDirection: "column"}}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/details/reservation/:reservationId" element={<VerificationDetailsView />} />
                <Route path="/unit-allow-list" element={<UnitAllowListComponent />} />
                <Route path="/previous-validation-list" element={<PassedValidationAllowList />} />
                <Route path="/good-behaviour-allow-list" element={<GoodBehaviourAllowListComponent />} />
              </Routes>
            </Box>
            <Footer />
          </Box>
        </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
};
