import React, {useCallback, useEffect, useMemo, useState} from "react";

import * as _ from "lodash";
import {getListByName, updateList} from "../../services/idv.service";
import {UnitAllowListInterface} from "../../modules/verification/types";
import {UnitAllowListItem} from "./unit-allow-list.types";

export function useUnitAllowList() {
  const [unitAllowList, setUnitAllowList] = useState<UnitAllowListInterface | undefined>(undefined);
  const [currentUnits, setCurrentUnits] = useState<Array<UnitAllowListItem>>([]);
  const [removedUnits, setRemovedUnits] = useState<Array<UnitAllowListItem>>([]);
  const [addedUnits, setAddedUnits] = useState<Array<UnitAllowListItem>>([]);
  const [filter, setFilter] = useState("");
  const [addUnitsInput, setAddUnitsInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        const response = await getListByName("unit-allow-list");
        const fetchedCurrentUnits = response.data.data?.list?.map((item: string) => ({name: item}));
        setUnitAllowList(response.data);
        setCurrentUnits(fetchedCurrentUnits ?? []);
      } catch (e) {
        console.error("Error getting Unit Allow List.");
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, []);

  const hasChanges = useMemo(() => {
    if (addedUnits.length > 0) {
      return true;
    }
    if (removedUnits.length > 0) {
      return true;
    }
    return false;
  }, [removedUnits, addedUnits]);

  const onRemoveUnit = useCallback(
    (item) => {
      const newCurrentUnits = currentUnits.filter((el) => el.name !== item.name);
      const newRemovedUnits = [...removedUnits, item];
      setCurrentUnits(_.orderBy(newCurrentUnits, "name"));
      setRemovedUnits(_.orderBy(newRemovedUnits, "name"));
    },
    [currentUnits, removedUnits]
  );

  const onRemoveRemovedUnit = useCallback(
    (item) => {
      const newCurrentUnits = [...currentUnits, item];
      const newRemovedUnits = removedUnits.filter((el) => el.name !== item.name);
      setCurrentUnits(_.orderBy(newCurrentUnits, "name"));
      setRemovedUnits(_.orderBy(newRemovedUnits, "name"));
    },
    [currentUnits, removedUnits]
  );

  const onRemoveAddedUnit = useCallback(
    (item) => {
      const newAddedUnits = addedUnits.filter((el) => el.name !== item.name);
      setAddedUnits(_.orderBy(newAddedUnits, "name"));
    },
    [addedUnits]
  );

  const onChangeFilter = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilter(event.target.value);
    },
    [setFilter]
  );

  const onChangeAddUnitInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAddUnitsInput(event.target.value);
    },
    [setAddUnitsInput]
  );

  const unitFilter = useCallback(
    (el: UnitAllowListItem) => {
      const filterLowerCase = filter.toLowerCase();
      return el.name.toLowerCase().includes(filterLowerCase);
    },
    [filter]
  );

  const onReset = useCallback(
    (newCurrentUnits?: Array<UnitAllowListItem>) => {
      if (newCurrentUnits) {
        setCurrentUnits(newCurrentUnits);
      } else {
        const restoredUnits = _.orderBy([...currentUnits, ...removedUnits], "name");
        setCurrentUnits(restoredUnits);
      }
      setAddedUnits([]);
      setRemovedUnits([]);
      setFilter("");
      setAddUnitsInput("");
    },
    [currentUnits, removedUnits]
  );

  const onSave = useCallback(async () => {
    setIsLoading(true);
    try {
      const newUnitAllowList = _.orderBy([...currentUnits, ...addedUnits], "name");
      const payload = {
        data: {list: newUnitAllowList.map((item) => item.name)},
      };
      if (unitAllowList?.id) {
        await updateList(unitAllowList?.id, payload);
      } else {
        console.error("List id is undefined.");
        throw new Error();
      }
      onReset(newUnitAllowList);
    } catch (e) {
      console.error("Error saving changes.");
    } finally {
      setIsLoading(false);
    }
  }, [currentUnits, addedUnits, onReset, unitAllowList]);

  const onAddUnit = useCallback(() => {
    const input = addUnitsInput.trim();
    if (input) {
      const myArray = input.split(",").map((item) => item.trim());
      const newsAddedUnits: Array<UnitAllowListItem> = [];
      for (const val of myArray) {
        const existInList = currentUnits.find((item) => item.name.toLowerCase() === val.toLowerCase());
        const existInDeletedList = removedUnits.find((item) => item.name.toLowerCase() === val.toLowerCase());
        const existInAddedUnits = addedUnits.find((item) => item.name.toLowerCase() === val.toLowerCase()); // Validate duplicated
        const existInNewsAddedUnits = newsAddedUnits.find((item) => item.name.toLowerCase() === val.toLowerCase()); //Validate duplicated
        if (existInList) {
          console.error(`Unit ${val} already exits in the unit allow list`);
          continue;
        } else if (existInDeletedList) {
          console.error(`Unit ${val} exists in deleted list, please remove it from the deleted list.`);
          continue;
        }
        if (val && !existInList && !existInDeletedList && !existInNewsAddedUnits && !existInAddedUnits) {
          newsAddedUnits.push({name: val.trim()});
        }
      }
      setAddedUnits(_.orderBy([...addedUnits, ...newsAddedUnits], "name"));
      setAddUnitsInput("");
    }
  }, [addUnitsInput, currentUnits, removedUnits, addedUnits]);

  const onKeyPressAddUnit = useCallback(
    (e: React.KeyboardEvent<HTMLImageElement>) => {
      if (e.key === "Enter") {
        onAddUnit();
      }
    },
    [onAddUnit]
  );

  return {
    currentUnits,
    addedUnits,
    removedUnits,
    filter,
    addUnitsInput,
    hasChanges,
    onRemoveUnit,
    onRemoveRemovedUnit,
    onRemoveAddedUnit,
    onReset,
    onSave,
    unitFilter,
    onChangeFilter,
    onChangeAddUnitInput,
    onAddUnit,
    onKeyPressAddUnit,
    isLoading,
  };
}
