import React from "react";
import {Box, Table, TableContainer} from "@mui/material";
import {TextFieldFilter} from "../../../common/components/Filters/TextFieldFilter/TextFieldFilter";
import {TableOptions, useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import {GoodBehaviourAllowListItem} from "../../../../views/GoodBehaviourAllowList/GoodBehaviourAllowList.types";
import {GlobalFilter} from "../../../common/components/Filters/GlobalFilter/GlobalFilter";
import {TableFooter} from "../../../common/components/Table/TableFooter/TableFooter";
import {TableHead} from "../../../common/components/Table/TableHead/TableHead";
import {TableBody} from "../../../common/components/Table/TableBody/TableBody";
import {getFullDate} from "../../../../utils/formats";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const ROW_HEIGHT = 49;

export interface IGoodBehaviourTableProps {
    data: Array<GoodBehaviourAllowListItem>;
    onDeleteConfirmation: (data: GoodBehaviourAllowListItem) => void;
}
export const GoodBehaviourTable: React.FC<IGoodBehaviourTableProps> = ({data, onDeleteConfirmation}) => {

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: TextFieldFilter,
        }),
        []
    );

    const columns = React.useMemo(
        () => [
            {
                Header: "Email",
                accessor: "email",
                width: "100%",
            },
            {
                Header: "Name",
                accessor: "name",
                width: "100%",
            },
            {
                Header: "Created at",
                accessor: "created_at",
                width: "100%",
                Cell: (props: any) => {
                    const dateStr = getFullDate(props.row.original?.created_at);
                    return <div>{dateStr}</div>;
                },
            },
            {
                Header: "Actions",
                width: 40,
                Cell: (props: any) => {
                    const data = props.row.original as any;
                    return (
                        <Box style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <IconButton onClick={(e) => onDeleteConfirmation(data)} aria-label="first page">
                                <DeleteIcon />
                            </IconButton>
                            <Box style={{height: 24, width: 24}} />
                        </Box>
                    );
                },
            },
        ],
        // eslint-disable-next-line
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            autoResetGlobalFilter: false,
            autoResetFilters: false,
            autoResetSortBy: false,
            autoResetPage: false,
            initialState: { pageSize: 5 }
        } as TableOptions<GoodBehaviourAllowListItem>,
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const emptyRows = Math.max(0, (1 + pageIndex) * pageSize - rows.length);
    return (
        <Box>
            <Box className="container-add-unit container-with-margin" style={{marginTop: 30, marginBottom: 30}}>
                <Box style={{width: 85}}>Search</Box>
                <Box style={{width: "84%"}}>
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        placeholder={"Write your search"}
                    />
                </Box>
            </Box>
            <TableContainer>
                <Table {...getTableProps()}>
                    <TableHead headerGroups={headerGroups}/>
                    <TableBody
                        getTableBodyProps={getTableBodyProps}
                        page={page}
                        emptyRows={emptyRows}
                        prepareRow={prepareRow}
                        colSpan={columns.length}
                        rowHeight={ROW_HEIGHT}
                    />
                    <TableFooter
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        pageCount={pageCount}
                        gotoPage={gotoPage}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        setPageSize={setPageSize}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        colSpan={columns.length}
                        rows={rows.length}
                    />
                </Table>
            </TableContainer>
        </Box>
    );
}
