import React, {useEffect} from "react";
import {TableInstance, useAsyncDebounce} from "react-table";
import {TextField} from "@mui/material";

export const TextFieldFilter = (props: TableInstance) => {
  const {
    column: {filterValue, setFilter},
    gotoPage,
  } = props;

  // const count = preFilteredRows.length
  const [value, setValue] = React.useState(filterValue);

  useEffect(() => {
    setValue(filterValue);
  }, [filterValue]);

  const setFilterDebounce = useAsyncDebounce((value) => {
    setFilter(value || undefined); // Set undefined to remove the filter entirely
    gotoPage(0);
  }, 250);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setFilterDebounce(e.target.value);
  };

  return (
    <TextField
      id="outlined-size-small"
      style={{minWidth: 170, width: "100%"}}
      size="small"
      value={value || ""}
      onChange={onChange}
    />
  );
};
